<template>
  <section class="dashboard-header">
    <div class="container-fluid">  
      <div class="card-header">
        <h3>Tabla de {{title}} {{awesome}}</h3>
        <div>   
        </div>
        
      </div>
  
      <div class="row">

        <div class="barra col-lg-6 col-6 mt-2 mb-1">
          <div class="form-inline">
            <input class="form-control mr-sm-2" type="search" aria-label="Search" v-on:keyup.enter="search" v-model="textoBuscar">
            <a class="nav-link fa fa-search" href="#" @click="search" title="Buscar"></a>   

            <b-form-select v-model="filtro" v-if="awesome === 'true'" size="sm" class="ml-3">
              <option value="todo">Todo</option>
              <option value="estatus">Estatus</option>
              <option value="carpeta">Carpeta</option>              
            </b-form-select>

          </div>
        </div>

        <div class="barra col-lg-3 col-3 mt-2 mb-1">
          <b-pagination align="right" size="sm" :limit="5" :total-rows="totalRows" v-model="currentPage" :per-page="perPage" @input="changeAvailablePage"/>
        </div>
  
        <div class="col-lg-2 col-2 mt-2 mb-0">
          <ul class="nav justify-content-end">

            <li class="nav-item">
              <a class="nav-link opciones1 fa fa-plus-square-o mt-15" href="#" @click="newRecord" title="Agregar"></a>
            </li>
            <li class="nav-item" v-if="awesome === 'false'">   /////// OK
              <a class="nav-link opciones1 fa fa-pencil-square-o" href="#" @click="editRecord" title="Modificar"></a>
            </li>
            <!-- <li class="nav-item" v-if="$store.getters.userAdmin === 'true'"> -->
            <li class="nav-item" v-if="awesome === 'false'">   /////// OK
              <a class="nav-link opciones1 fa fa-minus-square-o" href="#" @click="deleteRecord" title="Eliminar"></a>
            </li>

            <li class="nav-item" v-if="awesome === 'false'">
              &emsp;<a class="nav-link opciones2 fa fa-print" href="#" @click="addImprimir" title="Imprimir"></a>              
            </li>

            <!-- <li class="nav-item" v-if="awesome === 'false'">
              &emsp;<a class="nav-link opciones2 fa fa-envelope-o" href="#" @click="sendEmail" title="Enviar correo"></a>              
            </li> -->

           
            <li class="nav-item" v-if="awesome === 'false'">   /////// OK
              &emsp;<a class="nav-link opciones2 fa fa-print" href="#" @click="addImprimir" title="Imprimir"></a>              
            </li>

            <li class="nav-item" v-if="awesome === 'false'">   /////// OK
              &emsp;<a class="nav-link opciones2 fa fa-file-o" href="#" @click="addSolicitud" title="Solicitud"></a>              
            </li>
            <li class="nav-item" v-if="awesome === 'false'">   /////// OK
              <a class="nav-link opciones2 fa fa-file-text-o" href="#" @click="addCotizacion" title="Cotización"></a>
            </li>


            <li class="nav-item" v-if="awesome === 'false'">   /////// OK
              <a class="nav-link opciones2 fa fa-dollar" href="#" @click="addPago" title="Pago"></a>              
            </li>

            <li class="nav-item" v-if="awesome === 'false'">   /////// OK
              <a class="nav-link opciones2 fa fa-plus" href="#" @click="addPagoExt" title="Pago ext"></a>              
            </li>


            <li class="nav-item" v-if="awesome === 'false'">   /////// OK
              <a class="nav-link opciones2 fa fa-file-image-o" href="#" @click="addDocumentos" title="Documentos"></a>
            </li>

            <li class="nav-item" v-if="awesome === 'false'">   /////// OK
              <a class="nav-link opciones2 fa fa-folder-open-o" href="#" @click="addCarpeta" title="Carpeta"></a>
            </li>

            <li class="nav-item ml-3">
              <a class="nav-link ver fa fa-chevron-left" href="#" @click="verMenos" title="verMas"></a>
              <a class="nav-link ver fa fa-chevron-right" href="#" @click="verMas" title="verMas"></a>
            </li>

          </ul>
        </div>
      </div>
  
      <div id="fondoTabla" class="row bg-white has-shadow small">
        <div class="contenidoTabla col-lg-12 col-12">
  
          <table class="table bordered table-sm" id="tableCatGen" ref="myCatGen">
            <thead>
              <tr>
                <th v-for="col in titles" :key="col" >{{col}}</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="row in rows" :key="row.id" :class="{active: row.id == selected, cancelado: row.rol == 'Cancelado' }" :id="row.id" @click="rowSelect(row)" v-on:dblclick="editRecord">
                <td v-for="col in columns" v-bind:key="col" >{{row[col]}}</td>
              </tr>
            </tbody>
          </table>

        </div>
      </div>

    </div>  

  </section>
</template>

<script>

export default {
  data() {
    return {
      filtro: 'todo',
      selected: undefined,
      textoBuscar: '',
      searchQuery: '',
      currentPage: 1
    }
  },
  //name: 'selectedx',
  props: [
    'title', 'titles', 'awesome',
    'rows', 'columns',
    'totalRows', 'perPage'
  ],
  /*computed: {
    selectedx() {
      return this.selected;
    }
  },*/
  
  computed: {
      /*filteredData: function () {
        let filterKey = this.searchQuery && this.searchQuery.toLowerCase();
        let data = this.rows;
        if (filterKey) {
            data = data.filter(function (row) {
              return Object.keys(row).some(function (key) {
                  return String(row[key]).toLowerCase().indexOf(filterKey) > -1;
              })
            })
        }  
        return data;
      },*/
  },
  methods: {
    
    search(){
      this.$emit('search', this.textoBuscar, this.filtro)
    },

    changeAvailablePage(numPage){
      this.currentPage = numPage
      this.$emit('changeAvailablePage', numPage)
    }, 
    rowSelect(row){
      this.selected = row.id
      //console.log("select: " + this.selected);
      this.$emit('rowSelect', row)
    },
    selectRow(row){
      this.selected = row.id
      //console.log("chicho2: " + this.selected);
      //this.$refs.myTable.selectRow(7);
      this.$emit('selectRow', row)
    },
    findRecord() {
      this.$emit('findRecord')
    },
    newRecord() {
      this.$emit('newRecord')
    },
    editRecord() {
      this.$emit('editRecord')
    },
    deleteRecord() {
      this.$emit('deleteRecord')
    },
    addImprimir() {
      this.$emit('addImprimir')
    },
    addPago() {
      this.$emit('addPago')
    },
    addPagoExt() {
      this.$emit('addPagoExt')
    },
    addSolicitud() {
      this.$emit('addSolicitud')
    },
    addCotizacion() {
      this.$emit('addCotizacion')
    },
    addDocumentos() {
      this.$emit('addDocumentos')
    },
    addCarpeta() {
      this.$emit('addCarpeta')
    },
    verMenos(){
      var ele = document.querySelector('div.contenidoTabla');
      var pos = ele.scrollLeft - 500;
      ele.scrollTo(pos, 0);
    },
    verMas(){
      var ele = document.querySelector('div.contenidoTabla');
      var pos = ele.scrollLeft + 500;
      ele.scrollTo(pos, 0);
    }    
  }
}
</script>

<style scoped>
a {
  color: #000033 !important;
}

  section.dashboard-header {
    padding: 20px 0;
  }

  div#fondoTabla{height: 100%;}

  /* tr:hover{background-color: #d1ceeb ; font-weight: 500; color: black} */
  tr:hover{background-color: rgb(236, 231, 250)}
  tr.active{background-color: #a49be9; font-weight: 500; color: black}
  tr.cancelado{color: rgb(253, 0, 0);}
  
  div.contenidoTabla{overflow-x: scroll}
  a.nav-link.opciones1{padding: 0.4rem 0.3rem;}
  a.nav-link.opciones2{padding: 0.4rem 0.3rem;}
  a.nav-link.ver{padding: 0.5rem 0.0rem;}

  

</style>
