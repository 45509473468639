<template>
    <div id="usuariosTemplate">
        
        <cat-generico 
          title = "USUARIOS"
          :rows = 'usuarios' 
          :titles = 'titulos' 
          :columns = 'columnas'
          :totalRows = 'totalRows'        
          :perPage = 'perPage'   
          @search = 'buscar'     
          @newRecord = 'nuevo'
          @editRecord = 'editar'
          
          @rowSelect = 'seleccionar'  
          @changeAvailablePage = 'cambiarPagina' 
        ></cat-generico>

        

    </div>
</template>

<script>
import axios from 'axios';
  import CatGenerico from '@/components/tools/CatUsuarios.vue';

  export default {
    mounted: function() {
        this.getTablas()
      },
    components: {
      CatGenerico
    },
    data() {
      return {
        rowId: undefined,
        regiones: [{'id':'XXXX','nombre': 'NO ASIGNADA'},{'id': 'TSUR', 'nombre': 'TICKETS SUR'}, {'id': 'TBAJ', 'nombre': 'TICKETS BAJIO'}, {'id': 'TNOR', 'nombre': 'TICKETS NORTE'}, {'id': 'TPRO', 'nombre': 'TICKETS PROYECTOS'}],
        roles: [],
        usuarios: [],
        //usuarios2: [],
        titulos: ['ID', 'NOMBRE', 'PUESTO', 'CEL', 'TEL', 'REGION', 'ROL', 'EMAIL', 'PASS'],        
        columnas: ['id', 'name', 'puesto', 'cel', 'tel', 'region', 'rol', 'email', 'pass'],        
        totalRows: 450,
        currentPage: 1,
        perPage: 25,
        textoBuscar: ''
      }
    },
    methods: {
      fields(){
        var arr = [];
        for (let i in this.titulos) { 
          if( i*1 === 0 ){
            arr.push({'label': this.titulos[i], 'model': this.columnas[i], type: "input", inputType: "text", visible: false});              
          }else{
            if( this.columnas[i] === 'pass' ){
              arr.push({'label': this.titulos[i], 'model': this.columnas[i], type: "input", inputType: "password"});                          
            }else if(this.columnas[i] === 'region'){
              arr.push({'label': this.titulos[i], 'model': this.columnas[i], type: "select", values: this.regiones,
              selectOptions: {hideNoneSelectedText: true, id: 'id', name: 'nombre'}});            
            }else if(this.columnas[i] === 'rol'){
              arr.push({'label': this.titulos[i], 'model': this.columnas[i], type: "select", values: this.roles,
              selectOptions: {hideNoneSelectedText: true, id: 'id', name: 'nombre'}});            
            }else{
              arr.push({'label': this.titulos[i], 'model': this.columnas[i], type: "input", inputType: "text"});
            }                        
          }
        }
        return arr;
      },
      getTablas() {
        var token =  this.$cookies.get('token');
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;

        if(this.usuarios.length > 0){
          axios.all([
            this.getUsuarios()
          ])
          .then(axios.spread((usuariosResponse) => {
            this.setUsuarios(usuariosResponse);
          }))
        }else{
          axios.all([            
            this.getRoles(),
            this.getUsuarios()
          ])
          .then(axios.spread((rolesResponse, usuariosResponse) => {
            this.roles = rolesResponse.data;
            this.setUsuarios(usuariosResponse);
          }))
        }
      },
      setUsuarios(usuariosResponse){
        this.usuarios2 = JSON.parse( JSON.stringify( usuariosResponse.data.content ) ) ;
        this.usuarios = [];
        var items = usuariosResponse.data.content;              
        for (let item of items) {    

          var reg = this.regiones.find(row => row.id === item.region);

          if(JSON.stringify(reg) !== undefined){
            item.region = reg.nombre;
          }
          item.rol = item.rols.nombre;
          this.usuarios.push(item)
        }
        this.totalRows = usuariosResponse.data.totalElements;
      },
      getRoles() {
        return axios.get(process.env.VUE_APP_ROOT_API + "api/secure/roles");        
      },
      getUsuarios: function() {
        var params = "?page=" + (this.currentPage - 1) + "&limit=" + this.perPage + "&searchtext=" + this.textoBuscar;        
        return axios.get(process.env.VUE_APP_ROOT_API + "api/secure/usuarios" + params);        
      },
      buscar(texto){
        this.textoBuscar = texto;
        this.currentPage = 1;
        this.getTablas();          
      },
      cambiarPagina(numPage){
        this.currentPage = numPage;
        this.getTablas();
      },
      seleccionar(row){              
        this.rowId = row.id;          
      },        
      nuevo: function(){
        this.$router.push({ name: 'Usuario', params: { id: this.selected, model: {}, fields: {fields: this.fields()}  }})                      
      },        
      editar: function(){
        if (!(this.rowId === undefined)){  
          var model = this.usuarios2.find(row => row.id === this.rowId);                   
          model.rol = model.rols.id;
          this.$router.push({ name: 'Usuario', params: { id: this.rowId, model: model, fields: {fields: this.fields()}  }})            
          this.rowId = undefined;
        }else{
          alert('Debe seleccionar primero un usuario.');
        }
      },              
    }
  }
</script>

<style>
    div#usuariosTemplate table{font-size:  1em}
    div#usuariosTemplate th:nth-child(1), div#usuariosTemplate td:nth-child(1) {display: none;} /* id */
    div#usuariosTemplate th:nth-child(9), div#usuariosTemplate td:nth-child(9) {display: none;} /* pass */
    div#usuariosTemplate th:nth-child(10), div#usuariosTemplate td:nth-child(10) {display: none;} /* pin */
    div.container{height: 600px;}
</style>