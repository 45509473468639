<template>
  <section class="dashboard-header">
    <div class="container-fluid">
      <div class="card-header">
        <h3>Tabla de {{title}}</h3>
        <div></div>
      </div>

      

      <div class="row">
        <div class="barra col-lg-4 col-4 mt-2 mb-1">
          <div class="form-inline">
            <input
              class="form-control mr-sm-2"
              type="search"
              aria-label="Search"
              v-on:keyup.enter="search"
              v-model="textoBuscar"
            />
            <a class="nav-link fa fa-search" href="#" @click="search" title="Buscar"></a>

            <b-form-select v-model="filtro" v-if="awesome === 'true'" size="sm" class="ml-3">
              <option value="todo">Todo</option>
              <option value="estatus">Estatus</option>
              <option value="carpeta">Carpeta</option>
            </b-form-select>
          </div>
        </div>

        <div class="barra col-lg-3 col-3 mt-2 mb-1">
          <b-pagination
            align="right"
            size="sm"
            :limit="5"
            :total-rows="totalRows"
            v-model="currentPage"
            :per-page="perPage"
            @input="changeAvailablePage"
          />
        </div>

        <div class="col-lg-4 col-4 mt-2 mb-0">
          <ul class="nav justify-content-end">
            <li class="nav-item">
              <a
                class="nav-link opciones1 fa fa-plus-square-o"
                href="#"
                @click="newRecord"
                title="Nuevo"
              ></a>
            </li>
            <li class="nav-item" v-if="$store.getters.userAdmin === 'true'">
              <a
                class="nav-link opciones1 fa fa-pencil-square-o"
                href="#"
                @click="editRecord"
                title="Modificar"
              ></a>
            </li>
            <li class="nav-item" v-if="$store.getters.userAdmin === 'true'">
              <a
                class="nav-link opciones1 fa fa-minus-square-o"
                href="#"
                @click="deleteRecord"
                title="Eliminar"
              ></a>
            </li>

            <li class="nav-item" v-if="awesome === 'false'">
              &emsp;
              <a
                class="nav-link opciones2 fa fa-credit-card"
                href="#"
                @click="verDatosBanco"
                title="Datos Banco"
              ></a>
            </li>

            <li class="nav-item" v-if="$store.getters.userAdmin === 'true'">
              &emsp;
              <a
                class="nav-link opciones2 fa fa-print"
                href="#"
                @click="addImprimir"
                title="Imprimir"
              ></a>
            </li>            
                      
          </ul>
        </div>
      </div>

      <b-alert v-model="showDatosBanco" variant="info" class="w-40 ml-auto">
        <p class="mb-0">Banco: {{row.banco}}</p>
        <p class="mb-0">Titular: {{row.titular}}</p> 
        <p class="mb-0">Cuenta: {{row.cuenta}}</p> 
        <p class="mb-0">Clabe: {{row.clabe}}</p> 
        <p>Tarjeta: {{row.tarjeta}}</p> 
      <i class="fa fa-times" @click="ocultarDatosBanco"></i></b-alert>

      <div id="fondoTabla" class="row bg-white has-shadow small">
        <div class="contenidoTabla col-lg-12 col-12">
          
          <b-table hover selectable :select-mode="selectMode" @row-selected="onRowSelected" :items="items" :fields="fields">            
            <template v-slot:cell(ciudadd)="row">{{row.value.nombre}}, {{row.value.estado}} </template>
            <template v-slot:cell(tienda)="row">{{ row.value.iataop }} - {{row.value.iatafalsa}}</template>
            <template v-slot:cell(tiendaciudad)="row">{{ row.item.tienda.ciudadd.nombre.toUpperCase() }}, {{ row.item.tienda.ciudadd.estado.toUpperCase() }}</template>
            <template v-slot:cell(status)="row">{{ row.value }}</template>
          </b-table>

        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  data() {
    return {
      //showDatosBanco: false,
      filtro: "todo",
      selected: [],
      row: {},
      textoBuscar: "",
      searchQuery: "",
      currentPage: 1,
      selectMode: 'single'      
    };
  },  
  props: ["showDatosBanco", "title", "awesome", "items", "fields", "totalRows", "perPage"],
  
  computed: {},
  methods: {
    search() {
      this.$emit("search", this.textoBuscar, this.filtro);
    },

    changeAvailablePage(numPage) {
      this.currentPage = numPage;
      this.$emit("changeAvailablePage", numPage);
    },
    onRowSelected(items) {
      this.selected = items;     
      this.row = items[0]; 
      this.$emit("rowSelected", this.selected);
    },    
    rowSelect(row) {
      this.selected = row.id;
      //console.log("select: " + this.selected);
      this.$emit("rowSelect", row);
    },
    selectRow(row) {
      this.selected = row.id;
      //console.log("chicho2: " + this.selected);
      //this.$refs.myTable.selectRow(7);
      this.$emit("selectRow", row);
    },
    rowSelected() {      
      this.$emit("rowSelected", this.selected);
    },
    findRecord() {
      this.$emit("findRecord");
    },
    newRecord() {
      this.$emit("newRecord", this.selected);
    },
    editRecord() {
      this.$emit("editRecord", this.selected);
    },
    deleteRecord() {
      this.$emit("deleteRecord", this.selected);
    },
    ocultarDatosBanco(){
      this.$emit("ocultarDatosBanco", this.selected);
    },
    verDatosBanco() {      
      this.$emit("verDatosBanco", this.selected);
    },
    addImprimir() {
    }
  }
};
</script>

<style scoped>
a {
  color: #000033 !important;
}

i.fa.fa-times{position: absolute; top: 5px; right: 10px;}

section.dashboard-header {
  padding: 20px 0;
}

div#fondoTabla {
  height: 100%;
}

/* tr:hover{background-color: #d1ceeb ; font-weight: 500; color: black} */
tr:hover {
  background-color: rgb(236, 231, 250);
}
tr.active {
  background-color: #a49be9;
  font-weight: 500;
  color: black;
}

div.contenidoTabla {
  overflow-x: scroll;
}
a.nav-link.opciones1 {
  padding: 0.4rem 0.3rem;
}
a.nav-link.opciones2 {
  padding: 0.4rem 0.3rem;
}
a.nav-link.ver {
  padding: 0.5rem 0rem;
}
</style>
