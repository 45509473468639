<template>
    <section class="dashboard-header">
        <div class="container-fluid">  
            <div class="card-header">
                <div class="row">
                    <div class="col-lg-5 col-5">
                        <h2>{{msg}}</h2>
                    </div>

                    <div class="col-lg-7 col-7 text-centered">                                                                                                            
                            <a class="nav-link opciones1 fa fa-minus-square-o" href="#" @click="eliminar" title="Eliminar" v-if="$store.getters.userAdmin === 'true'"></a>                                                                                                        
                            <a class="nav-link opciones2 fa fa-print" href="#" @click="addImprimir" title="Imprimir"></a>              
                            <a class="nav-link opciones2 fa fa-file-o" href="#" @click="addSolicitud" title="Solicitud"></a>              
                            <a class="nav-link opciones2 fa fa-file-text-o" href="#" @click="addCotizacion" title="Cotización"></a>
                            <a class="nav-link opciones2 fa fa-dollar" href="#" @click="addPago" title="Pago"></a>              
                            <a class="nav-link opciones2 fa fa-plus" href="#" @click="addPagoExt" title="Pago ext"></a>              
                            <a class="nav-link opciones2 fa fa-file-image-o" href="#" @click="addDocumentos" title="Documentos"></a>
                            <a class="nav-link opciones2 fa fa-folder-open-o mr-auto" href="#" @click="addCarpeta" title="Carpeta"></a>                            
                    </div>
                </div>
            </div>

            <incidencia-form 
            :registro = 'incidencia'
            :proveedores = 'proveedores' 
            :prioridades = 'prioridades'
            :vacio = 'vacio'
            >                
            </incidencia-form>
            
        </div>

        <b-modal
        ref="refEliminar"
        title="Catálogo de Incidencias"
        cancel-title=" Cancelar "
        ok-title=" Eliminar "
        @ok="borrar"
        >
        <p class="my-4">Desea eliminar la incidencia {{rowId}}?</p>
        </b-modal>

    </section>
</template>

<script>

import axios from "axios";
import IncidenciaForm from '@/components/portal/views/IncidenciaForm.vue';

export default {
    name: 'incidencia',
    components: {
        IncidenciaForm
    },
    mounted: function() {
        this.incidencia.garantia =  this.incidencia.garantia === null ? 'No' : this.incidencia.garantia
    },
    data() {
        return {        
            msg: 'Registro de Incidencia ' + this.$route.params.id,
            rowId: this.$route.params.id,
            incidencia: this.$route.params.model,
            prioridades: this.$route.params.prioridades,
            proveedores: this.$route.params.proveedores,
            vacio: this.$route.params.vacio
        }
    },
    created() {
        //this.incidencia = this.$route.params.model;
        //this.getDatos();
    },    
    computed: {    
             
    },
    methods: { 
        eliminar: function() {
            this.$refs.refEliminar.show();            
        },
        
        borrar: function() {
            axios.defaults.headers.common["Authorization"] = "Bearer " + this.$cookies.get("token");
            axios.delete(process.env.VUE_APP_ROOT_API + "api/secure/incidencias/" + this.rowId)
            .then(
                response => {
                    this.getTablas();
                },
                error => {

                }
            );
        },      
        
        
        addImprimir(){
            this.$router.push({name: "Imprimir", params: { fechaCarpeta: incidencia.fecha, region: incidencia.region } });
        },
        addSolicitud(){

            var proveedorr = JSON.stringify(this.incidencia.proveedorr) + "**";

            console.log(proveedorr);

            if (proveedorr !=="undefined**" && proveedorr !== "null**") {
                axios.defaults.headers.common["Authorization"] = "Bearer " + this.$cookies.get("token");                
                axios.post(process.env.VUE_APP_ROOT_API + "api/secure/incidencias/solicitud", this.incidencia)
                    .then(
                    response => {
                        var params = {
                        id: this.rowId,
                        pdf: response.data.pdf,
                        folio: this.incidencia.cotizacion,
                        nameProv: this.incidencia.proveedorr.nombrecomercial,
                        emailProv: this.incidencia.proveedorr.email
                        };               
                        this.$router.push({ name: "Solicitud", params: params });
                    },
                    error => {
                        console.log("error:", JSON.stringify(error));
                    }
                    );
            } else {
            alert("Debe asignar un proveedor.");
            }
        },
        addCotizacion(){
            this.$router.push({name: "Cotizacion", params: {id: this.rowId, incid: this.incidencia}});
        },
        addPago(){            
            //console.log("d-d-d-d-d-d-d: ", JSON.stringify(this.incidencia));
            const params = { id: this.incidencia.id, region: this.incidencia.nomregion, ticket: this.incidencia.ticket, sucursal: this.incidencia.iata + '-' + this.incidencia.subiata,
            idusuario: this.incidencia.numusuario, tiendasubiata: this.incidencia.subiata, localidad: this.incidencia.tienda.ciudadd.nombre + ', ' + this.incidencia.tienda.ciudadd.estado, 
            proveedor: this.incidencia.proveedor, nombreProv: this.incidencia.proveedorr.nombrecomercial, 
            ciudadProv: this.incidencia.proveedorr.ciudadd.nombre + ", " + this.incidencia.proveedorr.ciudadd.estado };            
            
            console.log("params: ", JSON.stringify(params));
            
            this.$router.push({ name: "Pago", params: params });



        },
        addPagoExt(){
            const params = { capturarTicket: "Si", id: "1", region: "ADMN" };
            this.$router.push({ name: "PagoInt", params: params });
        },
        addDocumentos(){
            this.$router.push({ name: "Documento", params: { id: this.rowId } });
        },
        addCarpeta(){
            this.$router.push({
                name: "Carpeta",
                params: {
                numCarpeta: this.incidencia.carpeta,
                fechaCarpeta: this.incidencia.fecha,
                region: this.incidencia.nomregion
                }
            });
        }

    }
}
</script>

<style>
    small.text-info{color: #7000ff}
    div#incidencia{margin-top: 10px; padding-bottom: 20px;}
    section.dashboard-header{padding: 10px 0;}  
    div.autocomplete{width: 400px}
</style>
