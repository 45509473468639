<template>
  <div>
    <section class="dashboard-counts pt-3">
      <div class="container-fluid">
        <div class="card-header mb-2">
          <h3>Panel Ejecutivo</h3>
          <div></div>
        </div>
        <div class="row bg-white has-shadow">
          <div class="col-xl-6 col-sm-12">
            <b-card header="Incidencias Nuevas Diarias">
              <div class="small">
                <line-chart
                  :chart-data="data1"
                  :options="chartOptions"
                  :height="myHeight"
                ></line-chart>
              </div>
            </b-card>
          </div>

          <div class="col-xl-6 col-sm-12">
            <b-card header="Incidencias Nuevas Mensuales">
              <div class="small">
                <line-chart
                  :chart-data="data2"
                  :options="chartOptions"
                  :height="myHeight"
                ></line-chart>
              </div>
            </b-card>
          </div>

          <div class="col-xl-6 col-sm-6">
            <b-card header="Incidencias Terminadas Diarias">
              <line-chart
                :chart-data="data3"
                :options="chartOptions"
                :height="myHeight"
              ></line-chart>
            </b-card>
          </div>

          <div class="col-xl-6 col-sm-6">
            <b-card header="Incidencias Terminadas Mensuales">
              <line-chart
                :chart-data="data4"
                :options="chartOptions"
                :height="myHeight"
              ></line-chart>
            </b-card>
          </div>
          <!-- Item -->
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import axios from "axios";
import LineChart from "@/components/tools/LineChart.js";
export default {
  components: {
    LineChart,
  },
  data() {
    return {
      data1: {},
      data2: {},
      data3: {},
      data4: {},
      chartOptions: {},
      myHeight: 120,
    };
  },
  mounted() {
    this.fillData();
  },
  computed: {
    myStyles() {
      return {};
    },
  },
  methods: {
    setOptions() {
      this.chartOptions = {
        responsive: true,
        maintainAspectRatio: false,
      };
    },
    fillData() {
      var params = "?fecha=2019-05-01";
      axios.defaults.headers.common["Authorization"] =
        "Bearer " + this.$cookies.get("token");
      axios
        .get(process.env.VUE_APP_ROOT_API + "api/secure/graficas/dia" + params)
        .then(
          (response) => {
            this.data1 = response.data.dia;
            this.data2 = response.data.mes;
            this.data3 = response.data.diaTerm;
            this.data4 = response.data.mesTerm;
          },
          (error) => {}
        );
    },
    getRandomInt() {
      return Math.floor(Math.random() * (20 - 5 + 1)) + 5;
    },
  },
};
</script>

<style>
.small {
  height: 240px;
}
</style>