<template>
  <footer class="main-footer">
    <div class="container-fluid">
      <div class="row">
        <div class="col-sm-6">
          <p>Su Empresa &copy; 2017-2019 6.7.1435</p>
        </div>
        <div class="col-sm-6 text-right">
          <p>
            Powered by
            <a href="https://solinad21.com" class="external">SOLiNAD Apps</a>
          </p>
          <!-- Please do not remove the backlink to us unless you support further theme's development at https://bootstrapious.com/donate. It is part of the license conditions. Thank you for understanding :)-->
        </div>
      </div>
    </div>
  </footer>
</template>
<script>
export default {};
</script>
<style scoped>
footer.main-footer {
  padding: 10px 10px;
}
</style>
