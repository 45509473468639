<template>
  <div id="incidencia" class="row bg-white has-shadow">
    <div class="col-md-11">
      <form class="form form-horizontal mt-3 mb-3 ml-3">
        <div v-if="ver" class="form-group row">
          <label class="col-sm-3 form-control-label">Asignar región</label>
          <div class="col-sm-5">
            <select name="account" class="form-control" v-model="registro.nomregion">
              <option
                v-for="region in regiones"
                v-bind:key="region.id"
                :value="region.id"
              >{{ region.nombre }}</option>
            </select>
          </div>
        </div>

        <div class="form-group row">
          <label class="col-sm-3 form-control-label">Servicio Solicitado</label>
          <div class="col-sm-5">
            <select name="account" class="form-control" v-model="registro.serviciosolicitado">
              <option value="ATENDER">ATENDER</option>
              <option value="COTIZAR">COTIZAR</option>
            </select>
          </div>
        </div>

        <div class="form-group row">
          <label class="col-sm-3 form-control-label">Atendió</label>
          <div class="col-sm-6">
            <input
              type="text"
              class="form-control form-control-success"
              v-model="registro.atendio"
              name="registro.atendio"
            />
          </div>
        </div>

        <div class="form-group row">
          <label class="col-sm-3 form-control-label">Número de Ticket</label>
          <div class="col-sm-4">
            <input
              type="text"
              class="form-control form-control-success"
              v-model="registro.ticket"
              name="registro.ticket"
            />
          </div>
        </div>

        <div class="form-group row">
          <label class="col-sm-3 form-control-label">Tipo de Servicio</label>
          <div class="col-sm-5">
            <select name="account" class="form-control" v-model="registro.tiposervicio">             
              <option v-for="servicio in servicios" v-bind:key="servicio.id" :value="servicio.id">{{ servicio.nombre }}</option>              
            </select>
          </div>
        </div>

        <div class="form-group row">
          <label class="col-sm-3 form-control-label">Fecha de Apertura</label>
          <div class="col-sm-3">
            <input
              type="date"
              class="form-control form-control-success"
              v-model="registro.fecha"
              name="registro.fecha"
              readonly
            />
          </div>
        </div>

        <div class="form-group row">
          <label class="col-sm-3 form-control-label">Usuario</label>
          <div class="col-sm-6">
            <input
              type="text"
              class="form-control form-control-success"
              v-model="registro.usuario"
              name="registro.usuario"
            />
          </div>
        </div>

        <div class="form-group row">
          <label class="col-sm-3 form-control-label">IATA - Sub IATA</label>
          <div class="col-sm-9">
            <autocomplete
              ref="autocomplete"
              placeholder="Buscar IATA"
              :request-headers="authHeaders"
              :source="source"
              :results-display="formattedDisplay"
              :initialDisplay="initialDisplay"
              resultsValue="oso"
              @selected="setXHRValue"
            ></autocomplete>
            <small class="text-info">{{ iataSearched }}</small>
          </div>
        </div>

        <div class="form-group row">
          <label class="col-sm-3 form-control-label">Prioridad</label>
          <div class="col-sm-2">
            <select name="account" class="form-control" v-model="registro.prioridad">
              <option v-for="prioridad in prioridades" v-bind:key="prioridad.id" v-bind:value="prioridad.nombre">
                {{ prioridad.nombre }}
              </option>              
            </select>
          </div>
        </div>

        <div class="form-group row">
          <label class="col-sm-3 form-control-label">Garantia</label>
          <div class="col-sm-2">
            <select name="atiempo" class="form-control" v-model="registro.garantia">
              <option value="Si">Si</option>
              <option value="No" selected>No</option>
            </select>
          </div>
        </div>

        <div class="form-group row">
          <label class="col-sm-3 form-control-label">Descripción</label>
          <div class="col-sm-9">
            <textarea
              rows="4"
              cols="200"
              class="form-control form-control-success"
              v-model="registro.descripcion"
              name="registro.descripcion"
            ></textarea>
          </div>
        </div>

        <div class="form-group row">
          <label class="col-sm-3 form-control-label">Fecha de Cotización</label>
          <div class="col-sm-3">
            <input
              type="date"
              class="form-control form-control-success"
              v-model="registro.fechacotizacion"
              name="registro.fechacotizacion"
            />
          </div>
        </div>

        <div class="form-group row">
          <label class="col-sm-3 form-control-label">Fecha de Autorizacion</label>
          <div class="col-sm-3">
            <input
              type="date"
              class="form-control form-control-success"
              v-model="registro.fechaautorizacion"
              name="registro.fechaautorizacion"
            />
          </div>
        </div>

        <div class="form-group row">
          <label class="col-sm-3 form-control-label">Fecha de Cierre</label>
          <div class="col-sm-3">
            <input
              type="date"
              class="form-control form-control-success"
              v-model="registro.fechacierre"
              name="registro.fechacierre"
            />
          </div>
        </div>

        <div class="row">
          <div class="col-8">
            <div class="form-group row">
              <label class="col-4-37-50 form-control-label">Estatus</label>
              <div class="col-5">
                <select name="account2" class="form-control" v-model="registro.idstatus" :disabled="optionDisabled">
                  <option
                    v-for="est in estatus"
                    v-bind:key="est.id"
                    :value="est.id"
                  >{{ est.name }}</option> 
                </select>
              </div>
            </div>

            <div class="form-group row">
              <label class="col-4-37-50 form-control-label">Costo Proveedor</label>
              <div class="col-sm-4">
                <input
                  type="text"
                  class="form-control form-control-success"
                  v-model="registro.importe"
                  name="registro.importe"
                />
              </div>
            </div>

            <div class="form-group row">
              <label class="col-4-37-50 form-control-label">Anticipo</label>
              <div class="col-6 ">
                <div class="row">
                  <div class="col-sm-6">
                    <input
                      type="text"
                      class="form-control form-control-success"
                      v-model="registro.anticipo"
                      name="registro.anticipo"
                    />            
                  </div>
                  <div class="col-6 border bg-light text-dark">
                    <p class="mb-0 mt-1 float-right">
                    Saldo: $ {{ (registro.importe - registro.anticipo).toFixed(2) }}
                    </p> 
                  </div>


                </div>
              </div>          
            </div>

            <div class="form-group row">
              <label class="col-4-37-50 form-control-label">Importe cliente</label>
              <div class="col-sm-4">
                <input
                  type="text"
                  class="form-control form-control-success"
                  v-model="registro.importedhl"
                  name="registro.importedhl"
                />
              </div>
            </div>

          </div>
          <div class="col-4">
            <span class="f-90">Lista de pagos:</span>
            <ul class="border">

              <li v-for="pago in pagos" v-bind:key="pago.id" v-bind:class="[pago.status === '4 Finalizado' ? 'f-90' : 'f-90 text-danger']">
                {{ pago.fecha }} - $ {{ Number(pago.cantidad).format(2) }}
              </li>

            </ul>
          </div>
        </div>

        

        <div class="form-group row">
          <label class="col-sm-3 form-control-label">A Tiempo</label>
          <div class="col-sm-2">
            <select name="atiempo" class="form-control" v-model="registro.atiempo">
              <option value="Si">Si</option>
              <option value="No" selected>No</option>
            </select>
          </div>
        </div>

        <div class="form-group row">
          <label class="col-sm-3 form-control-label">Carpeta</label>
          <div class="col-sm-3">
            <input
              type="text"
              class="form-control form-control-success"
              v-model="registro.carpeta"
              name="registro.carpeta"
            />
          </div>
        </div>

        <div class="form-group row">
          <label class="col-sm-3 form-control-label">Comentarios carpeta</label>
          <div class="col-sm-9">
            <textarea
              rows="2"
              cols="200"
              class="form-control form-control-success"
              v-model="registro.comentarioscarpeta"
              
            ></textarea>
          </div>
        </div>

        <b-alert
          :show="dismissCountDown"
          dismissible
          variant="warning"
          @dismissed="dismissCountDown=0"
          @dismiss-count-down="countDownChanged"
        >{{mensajeAltaProv}} Se grabo correctamente...</b-alert>

        <div class="form-group row">
          <label class="col-sm-3 form-control-label">Proveedor</label>
          <div class="col-sm-9">
            <div class="row">
              <div class="col-sm-9">
                <autocomplete
                  ref="autocomplete"
                  placeholder="Buscar proveedor"
                  :request-headers="authHeaders"
                  :source="sourceprovs"
                  :results-display="formattedDisplayProvs"
                  :initialDisplay="initialDisplayProvs"
                  input-class="inputProv"
                  @selected="setDatosProv"
                  @clear="clear"
                ></autocomplete>
              </div>
              <div class="col-sm-3">
                <i
                  class="fa fa-address-book-o"
                  title="Agregar Proveedor"
                  @click="showAltaRapidadProv"
                ></i>
                <b-modal ref="altaRapidadProv" title="Agregar Proveedor Rapido" @ok="okx">
                  <div>
                    <b-form-group label-cols="4" label-cols-lg="4" label="Codigo Giro">
                      <b-form-select v-model="codigo" :options="giros" class="w-100"></b-form-select>
                    </b-form-group>

                    <b-form-group
                      label-cols="4"
                      label-cols-lg="4"
                      label="Nombre Proveedor"
                      label-for="nombre"
                    >
                      <b-form-input id="nombre" v-model="nombre"></b-form-input>
                    </b-form-group>
                    <b-form-group
                      label-cols="4"
                      label-cols-lg="4"
                      label="Ciudad"
                      label-for="ciudad"
                    >
                      <b-form-select v-model="ciudad" :options="options"></b-form-select>
                    </b-form-group>

                    <b-form-group
                      label-cols="4"
                      label-cols-lg="4"
                      label="Telefono"
                      label-for="telefono"
                    >
                      <b-form-input id="telefono" v-model="telefono"></b-form-input>
                    </b-form-group>

                    <b-form-group
                      label-cols="4"
                      label-cols-lg="4"
                      label="Correo Electronico"
                      label-for="email"
                    >
                      <b-form-input id="email" v-model="email"></b-form-input>
                    </b-form-group>
                  </div>
                </b-modal>
              </div>
            </div>

            <small class="text-info">{{ provSearched }}</small>
          </div>
        </div>


        

        <div class="form-group row">
          <label class="col-sm-3 form-control-label">Observaciones</label>
          <div class="col-sm-9">
            <div class="p-25" id="div-mimicking-textarea"></div>
            <textarea
              rows="3"
              cols="200"
              class="form-control form-control-success"
              v-model="observacionesNew"
              name="registro.observaciones"
            ></textarea>
          </div>
        </div>

        <div class="form-group row mt-3">
          <div class="col-7">
            <b-btn class="float-left mr-1 ml-1 mt-3" variant="secundary" @click="regresar">Cerrar</b-btn>
            <!-- <b-btn class="float-left mr-1 ml-1 mt-3" variant="primary" @click="grabar">Grabar y cerrar</b-btn> -->
            <b-btn class="float-left ml-5 mt-3" variant="primary" @click="grabar2">Grabar</b-btn>  
          </div>
          <b-alert
            class="col-5"              
            :show="dismissCountDown2"
            dismissible
            variant="warning"
            @dismissed="dismissCountDown2=0"
            @dismiss-count-down="countDownChanged2"
          >
          Grabando ...
          </b-alert>      
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Autocomplete from "vuejs-auto-complete";

Number.prototype.format = function(n, x) {
    var re = '\\d(?=(\\d{' + (x || 3) + '})+' + (n > 0 ? '\\.' : '$') + ')';
    return this.toFixed(Math.max(0, ~~n)).replace(new RegExp(re, 'g'), '$&,');
};

export default {
  name: "incidencias-form",
  components: {
    Autocomplete,
  },
  data() {
    return {
      mensajeAltaProv: "",      
      ver: this.$store.getters.userAdmin === "true",
      inicio: true,
      dismissSecs: 3,
      dismissCountDown: 0,
      iataSearched: "",
      codigo: "",
      giros: [
        { value: "PTA", text: "PTA-Puertas de Aluminio" },
        { value: "ACC", text: "ACC-Aires Acondicionados" },
        { value: "PLO", text: "PLO-Plomeria" },
        { value: "COR", text: "COR-Cortinas de Acero" },
        { value: "LOS", text: "LOS-Losetas y Pizos" },
        { value: "ELE", text: "ELE-Electricista" },
        { value: "PIN", text: "PIN-Pintura" },
        { value: "ROC", text: "ROC-Tablaroca" },
        { value: "PLA", text: "PLA-Plagas y Fumigaciones" },
        { value: "HER", text: "HER-Herreria" },
        { value: "MAN", text: "MAN-Mantenimiento" },
        { value: "PUB", text: "PUB-Publicidad" },
        { value: "FER", text: "FER-Ferreterias" },
        { value: "CER", text: "CER-Cerrajeria" },
        { value: "CON", text: "CON-Construccion" },
        { value: "PAU", text: "PAU-Puertas Automaticas" },
        { value: "ALS", text: "ALS-Alarma y Seguridad" },
        { value: "PER", text: "PER-Persianas" },
        { value: "MEC", text: "MEC-Servicio Automotriz" },
        { value: "REN", text: "REN-Renta de Bodega u Oficinas" },
        { value: "MUL", text: "MUL-Multiservicios" },
        { value: "TIE", text: "TIE-Tiendas" },
        { value: "CAR", text: "CAR-Carpinteria" },
        { value: "TEC", text: "TEC-Servicio Tecnico" },
        { value: "PAQ", text: "PAQ-Paqueteria" },
        { value: "HOT", text: "HOT-Hotel" }
      ].sort(function(a, b) {
        var x = a.value.toLowerCase();
        var y = b.value.toLowerCase();
        if (x < y) {
          return -1;
        }
        if (x > y) {
          return 1;
        }
        return 0;
      }),
      dismissSecs2: 1,
      dismissCountDown2: 0,
      nombre: "",
      ciudad: null,
      telefono: null,
      email: null,
      options: [],
      
      provSearched: "", //initialValue: 'OAX-AMP',
      provSearched2: "",
      provSearched3: "",
      provSearched4: "",
      provSearched5: "",
      initialDisplay: "",
      initialDisplayProvs: "",
      initialDisplayProvs2: "",
      initialDisplayProvs3: "",
      initialDisplayProvs4: "",
      initialDisplayProvs5: "",
      regiones: [
        { id: "XXXX", nombre: "NO ASIGNADA" },
        { id: "TSUR", nombre: "TICKETS SUR" },
        { id: "TBAJ", nombre: "TICKETS BAJIO" },
        { id: "TNOR", nombre: "TICKETS NORTE" },
        { id: "TPRO", nombre: "TICKETS PROYECTOS" },
      ],      
      optionDisabled: false,
      observaciones: "",
      observacionesNew: "",
      estatus: [],
      pagos: [],
      servicios: []
    };
  },

  props: ["msg","prioridades", "registro", "vacio"],

  created() {},
  mounted: function () {
    this.oso();
  },
  computed: {
    authHeaders() {
      return {
        Authorization: "Bearer " + this.$cookies.get("token"),
      };
    },
    sourceprovs() {
      if (this.inicio) {
        if (
          this.registro.proveedorr !== undefined &&
          JSON.stringify(this.registro.proveedorr) !== "null"
        ) {
          this.initialDisplayProvs = this.registro.proveedorr.nombrecomercial;
          this.provSearched = (
            this.registro.proveedorr.nombrecomercial +
            " " +
            this.registro.proveedorr.telefono +
            " (" +
            this.registro.proveedorr.titular +
            ") " +
            this.registro.proveedorr.ciudadd.nombre
          ).toUpperCase();
        }        
      }

      var url = process.env.VUE_APP_ROOT_API + "api/secure/proveedores/";
      return url;
    },
    source() {
      if (this.vacio) {
        this.initialDisplay = "ABB-ABB";
        this.iataSearched = "sadasdasdas";
      } else {
        if (this.inicio) {
          if (
            JSON.stringify(this.registro.iata) === "null" &&
            JSON.stringify(this.registro.subiata === "null")
          ) {
            this.registro.iata = "ABB";
            this.registro.subiata = "ABB";
            this.initialDisplay =
              this.registro.iata + "-" + this.registro.subiata;
            this.iataSearched = "";
          } else {
            this.initialDisplay =
              this.registro.iata + "-" + this.registro.subiata;
            this.iataSearched =
              this.registro.tienda.iataop +
              "-" +
              this.registro.tienda.iatafalsa +
              ": " +
              this.registro.tienda.domicilio +
              " " +
              this.registro.tienda.num_ext +
              " Col. " +
              this.registro.tienda.colonia +
              ". " +
              this.registro.tienda.ciudadd.nombre +
              ", " +
              this.registro.tienda.ciudadd.estado;
          }
        }
      }
      var url = process.env.VUE_APP_ROOT_API + "api/secure/tiendas/";
      return url;
    },
  },
  methods: {
    validarStatus(){
      var hoy = this.$moment();
      var cierre = this.$moment(this.registro.fechacierre);
      if(cierre.isValid()){        

        var opciones = ""
        this.estatus.forEach(v => {
          if(v.obligatorio === 1){
            opciones += v.name + " ";
          }
        });

        // var opciones = "CERRADO CANCELADO TERMINADO NO AUTORIZADO";
        opciones.includes(this.registro.status)
        if( hoy.diff(cierre, 'days') > 7 && opciones.includes(this.registro.estatus.name) ){          
          this.optionDisabled = true;
        }else{
          this.optionDisabled = false;
        }
      }
    },
    clear() {
      this.registro.proveedor = null;
      this.provSearched = "";
      this.oso();
    },
    clear2() {
      this.registro.proveedor2 = null;
      this.provSearched2 = "";
      this.oso();
    },
    clear3() {
      this.registro.proveedor3 = null;
      this.provSearched3 = "";
      this.oso();
    },
    clear4() {
      this.registro.proveedor4 = null;
      this.provSearched4 = "";
      this.oso();
    },
    clear5() {
      this.registro.proveedor5 = null;
      this.provSearched5 = "";
      this.oso();
    },
    bloquearObservaciones(){  
      var objDiv = document.getElementById("div-mimicking-textarea");
      if(this.observacionesNew.length > 0){
        objDiv.innerHTML = this.registro.observaciones
        var brinco =  ( this.registro.observaciones !== null && this.registro.observaciones.length > 0 ? "<hr>" + this.observacionesNew : this.observacionesNew)
        objDiv.innerHTML += brinco;                
        this.registro.observaciones += brinco;
        this.observacionesNew = "";                
      }else{
        objDiv.innerHTML = this.registro.observaciones;
      }
      objDiv.scrollTop = objDiv.scrollHeight - objDiv.clientHeight;
      //this.observaciones.innerHTML = this.registro.observaciones + "<br/>" + this.observacionesNew;           
      
    },
    oso() {
      this.bloquearObservaciones();      

      this.registro.tiposervicio = (JSON.stringify(this.registro.tiposervicio) === "null") ? "00" : this.registro.tiposervicio;

      //this.registro.prioridad = "R1";
      this.verProv2 = JSON.stringify(this.registro.proveedor) !== "null";
      this.verProv3 = JSON.stringify(this.registro.proveedor2) !== "null";
      this.verProv4 = JSON.stringify(this.registro.proveedor3) !== "null";
      this.verProv5 = JSON.stringify(this.registro.proveedor4) !== "null";

      axios.defaults.headers.common["Authorization"] = "Bearer " + this.$cookies.get("token");
      axios
      .all([
        this.getEstatus(),
        this.getPagos(),
        this.getServicios()
      ])
      .then(
        axios.spread(
          (
            estatusResponse,
            pagosResponse, 
            serviciosResponse           
          ) => {
            this.estatus = estatusResponse.data;
            this.validarStatus();
            this.pagos = pagosResponse.data.content;            
            this.servicios = serviciosResponse.data;
          }
        )
      );
    },
    getServicios() {
      return axios.get(process.env.VUE_APP_ROOT_API + "api/secure/servicios");
    },
    getEstatus() {
      return axios.get(process.env.VUE_APP_ROOT_API + "api/secure/estatus");
    },

    getPagos() {
      return axios.get(process.env.VUE_APP_ROOT_API + "api/secure/pagos/ticket/" + this.registro.ticket);
    },

    formattedDisplay(result) {
      if (JSON.stringify(result.svpname) === "null") {
        result.svpname = "ND - NO DISPONIBLE";
      }
      return (
        result.iataop +
        " - " +
        result.iatafalsa +
        " : " +
        result.svpname.split(" - ")[1] +
        ""
      );
    },
    formattedDisplayProvs(result) {
      var ciudad = "";
      if (JSON.stringify(result.ciudadd) !== "null") {
        ciudad = result.ciudadd.nombre;
      }

      return (
        result.nombrecomercial +
        " (" +
        result.titular +
        ") " +
        ciudad
      ).toUpperCase();
    },
    setDatosProv(obj) {
      this.registro.proveedor = obj.value;
      this.provSearched = this.getDatosProvsProv(obj);
    },
    setDatosProv2(obj) {
      this.registro.proveedor2 = obj.value;
      this.provSearched2 = this.getDatosProv(obj);
    },
    setDatosProv3(obj) {
      this.registro.proveedor3 = obj.value;
      this.provSearched3 = this.getDatosProv(obj);
    },
    setDatosProv4(obj) {
      this.registro.proveedor4 = obj.value;
      this.provSearched4 = this.getDatosProv(obj);
    },
    setDatosProv5(obj) {
      this.registro.proveedor5 = obj.value;
      this.provSearched5 = this.getDatosProv(obj);
    },
    getDatosProv(obj) {
      var ciudad = "";
      if (JSON.stringify(obj.selectedObject.ciudadd) !== "null") {
        ciudad = obj.selectedObject.ciudadd.nombre;
      }
      this.oso();
      return (
        obj.selectedObject.nombrecomercial +
        " (" +
        obj.selectedObject.titular +
        ") " +
        ciudad
      ).toUpperCase();
    },
    getDatosProv2(prov) {
      var ciudad = "";
      if (JSON.stringify(prov.ciudadd) !== "null") {
        ciudad = prov.ciudadd.nombre;
      }
      this.oso();
      return (
        prov.nombrecomercial +
        " (" +
        prov.titular +
        ") " +
        ciudad
      ).toUpperCase();
    },
    setXHRValue(obj) {
      this.inicio = false;
      //this.apiResults = obj;
      this.iataSearched =
        obj.selectedObject.iataop +
        "-" +
        obj.selectedObject.iatafalsa +
        ": " +
        obj.selectedObject.domicilio +
        " " +
        obj.selectedObject.num_ext +
        " Col. " +
        obj.selectedObject.colonia +
        ". " +
        obj.selectedObject.ciudad +
        ", " +
        obj.selectedObject.estado;

      this.$refs.autocomplete.value = obj.selectedObject.display + "";

      this.registro.iata = obj.selectedObject.iataop;
      this.registro.subiata = obj.selectedObject.iatafalsa;
    },
    countDownChanged(dismissCountDown) {
      this.dismissCountDown = dismissCountDown;
    },
    proveedorRapido() {},
    grabar() {
      this.bloquearObservaciones();
      var incid = JSON.parse(JSON.stringify(this.registro));      
      axios.defaults.headers.common["Authorization"] =
        "Bearer " + this.$cookies.get("token");
      axios
        .post(process.env.VUE_APP_ROOT_API + "api/secure/incidencias", incid)
        .then(
          (response) => {            
            this.$router.replace({ path: "/portal/incidencias" });
          },
          (error) => {
            //console.log("Error: " + JSON.stringify( error.data) );
          }
        );
    },
    grabar2() {
      this.dismissCountDown2 = this.dismissSecs2           
      this.bloquearObservaciones();
      var incid = JSON.parse(JSON.stringify(this.registro));

      incid.ticket = incid.ticket.replace(/\t/g, '');

      axios.defaults.headers.common["Authorization"] =
        "Bearer " + this.$cookies.get("token");
      axios
        .post(process.env.VUE_APP_ROOT_API + "api/secure/incidencias", incid)
        .then(
          (response) => {  

            const ret = response.data;
            
            if(ret === "OK"){
              this.validarStatus();            
            }else{
              alert("ERROR: " + ret);
            }
            
            
          },
          (error) => {
            //console.log("Error: " + JSON.stringify( error.data) );
          }
        );
    },
    regresar() {
      this.$router.replace({ path: "/portal/incidencias" });
    },
    showAltaRapidadProv() {
      axios.defaults.headers.common["Authorization"] =
        "Bearer " + this.$cookies.get("token");
      axios.get(process.env.VUE_APP_ROOT_API + "api/secure/ciudades").then(
        (response) => {
          var items = response.data;
          for (let item of items) {
            var ciudad = {};
            ciudad.value = item.id;
            ciudad.text = item.nombre;
            this.options.push(ciudad);
          }
          this.$refs["altaRapidadProv"].show();
        },
        (error) => {
          //console.log("Error: " + JSON.stringify( error.data) );
        }
      );
    },
    countDownChanged2(dismissCountDown) {
      this.dismissCountDown2 = dismissCountDown;
    },
    okx() {
      axios.defaults.headers.common["Authorization"] =
        "Bearer " + this.$cookies.get("token");
      axios
        .post(process.env.VUE_APP_ROOT_API + "api/secure/proveedores", {
          codigo: this.codigo,  
          nombrecomercial: this.nombre,
          ciudad: this.ciudad,
          telefono: this.telefono,
          email: this.email,
        })
        .then(
          (response) => {
            if (this.verProv5) {
              document.getElementsByClassName(
                "inputProv5"
              )[0].value = this.nombre;
              this.registro.proveedor5 = response.data.id;
              this.provSearched5 = this.getDatosProv2(response.data);
            } else if (this.verProv4) {
              document.getElementsByClassName(
                "inputProv4"
              )[0].value = this.nombre;
              this.registro.proveedor4 = response.data.id;
              this.provSearched4 = this.getDatosProv2(response.data);
            } else if (this.verProv3) {
              document.getElementsByClassName(
                "inputProv3"
              )[0].value = this.nombre;
              this.registro.proveedor3 = response.data.id;
              this.provSearched3 = this.getDatosProv2(response.data);
            } else if (this.verProv2) {
              document.getElementsByClassName(
                "inputProv2"
              )[0].value = this.nombre;
              this.registro.proveedor2 = response.data.id;
              this.provSearched2 = this.getDatosProv2(response.data);
            } else {
              document.getElementsByClassName(
                "inputProv"
              )[0].value = this.nombre;
              this.registro.proveedor = response.data.id;
              this.provSearched = this.getDatosProv2(response.data);
            }

            this.mensajeAltaProv = "Se grabo correctamente...";
            this.dismissCountDown = this.dismissSecs;
            //this.$router.replace({ path: '/portal/proveedores' });
          },
          (error) => {
            //console.log("Error: " + JSON.stringify( error.data) );
          }
        );
    },
  },
};
</script>

<style>
  .f-90{
    font-size: 0.90rem;
  }
  .col-4-37-50{
    -ms-flex: 0 0 37.50%;
    -webkit-box-flex: 0;
    flex: 0 0 37.50%;
    max-width: 37.50%;
    position: relative;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
  }
  small.text-info {
    color: #7000ff;
  }
  div#incidencia {
    margin-top: 10px;
    padding-bottom: 20px;
  }
  section.dashboard-header {
    padding: 10px 0;
  }
  div.autocomplete {
    width: 400px;
  }
  i.fa:hover {
    cursor: pointer;
  }
  #div-mimicking-textarea {border: solid 1px #dee2e6; min-height: 2rem; max-height: 6rem; background-color: #f5f5f5; overflow-y: auto;}
</style>
