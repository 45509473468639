<template>
     <div class="page login-page">
      <div class="container d-flex align-items-center">
        <div class="form-holder has-shadow">
          <div class="row">
            <!-- Logo & Information Panel-->
            <div class="col-lg-6">
              <div class="info d-flex align-items-center">
                <div class="content">
                  <div class="logo">
                    <h1>Sistema de Control de Incidencias</h1>
                  </div>
                  <p>Servicios y Mantenimiento Preventivo y Correctivo</p>
                </div>
              </div>
            </div>
            <!-- Form Panel    -->
            <div class="col-lg-6 bg-white">
              <template>
                <div class="form d-flex align-items-center">
                  <div class="content">
                    <div method="post" class="form-validate">
                      <div class="form-group">
                        <label for="login-username" class="label-material">Correo Electrónico</label>
                        <input  v-model="mail" id="login-username" type="text" name="loginUsername" required data-msg="Please enter your username" class="input-material border-login">
                        
                      </div>
                      <div class="form-group">
                        <label for="login-password" class="label-material">Contraseña</label>
                        <input  v-model="pass" id="login-password" type="password" name="loginPassword" required data-msg="Please enter your password" class="input-material border-login">                      
                      </div>
                      
                      <a id="login" href="#" class="btn btn-primary" v-on:click="login">Entrar</a>
                      <div><p>{{mess}}</p></div>

                      <!-- This should be submit button but I replaced it with <a> for demo purposes-->
                    </div>
                    
                  </div>
                </div>
              </template>              
            </div>
          </div>
        </div>
      </div>
      <div class="copyrights text-center">
        <p>Powered by <a href="https://solinad21.com" class="external">SOLiNAD Apps</a>
          <!-- Please do not remove the backlink to us unless you support further theme's development at https://bootstrapious.com/donate. It is part of the license conditions. Thank you for understanding :)-->
        </p>
      </div>
    </div>
</template>

<style scoped>

.login-page .form-holder .info {
    background: #000033;
    color: #fff;
}
div.container{max-width: 800px;}
.login-page .form-holder .info, .login-page .form-holder .form {
    min-height: 40vh; 
}
.login-page .form-holder .info h1 {
    font-size: 1.5em; 
}
.border-login{border: solid 1px rgb(203, 227, 255);}

</style>


<script>
  import axios from 'axios';
  //import VueJwtDecode from 'vue-jwt-decode'
  
  export default {
      data() {
          return {              
              mail : "",
              pass : "",
              mess : "",
              user: {
            "id": null,
            "name": null,
            "office": null,
            "role": null,
            "email": null,
            "salt": null,
            "pass": null
          }    
          };   
      },

      methods: {        
        login() {
          //axios.defaults.headers.common['Authorization'] = null; 
          console.log(process.env.VUE_APP_ROOT_API + "api/login");
          axios.post(process.env.VUE_APP_ROOT_API + "api/login", {
            email: this.mail,
            pass: this.pass
          })
          .then((response)  =>  {                    
              let token = response.data;
              this.$store.commit('usuario', token)
              var timeOut = process.env.VUE_APP_SESSION_TIMEOUT;
              this.$cookies.set("token", token, timeOut);              
              this.$router.replace('/portal');              //sconsole.log()
          }, (error)  =>  {
              console.log("Error en login" + JSON.stringify(error))
              if(JSON.stringify(error).search('"response"')>=0){
                this.mess = (JSON.stringify(error.response.data)+'.').split('"').join("").replace('ERROR','ERROR (Status: ' + error.response.status + ')' + '');  
              }else{
                this.mess = "ERROR : URL no encontrada.";                  
              }                               
          })                          
        }        
      }
  }
</script>

