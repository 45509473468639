import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export const store = new Vuex.Store({
  state: {
    userName: '',
    userRole: '',
    userAdmin: '',
    userRegion: '',
    userEmail: '',
    textCiudades: '',
    lastRowInc: '',
    lastRowPro: 0,
    lastRowTie: ''
  },
  mutations: {
    usuario(state, token) {
        state.userName = JSON.parse(atob(token.split('.')[1])).name    
        state.userRole = JSON.parse(atob(token.split('.')[1])).role
        state.userAdmin = JSON.parse(atob(token.split('.')[1])).admin
        state.userRegion = JSON.parse(atob(token.split('.')[1])).region
        state.userEmail = JSON.parse(atob(token.split('.')[1])).sub        
    },
    textCiudades(state, text){
      state.textCiudades = text;
    },
    lastRowInc(state, text){
      state.lastRowInc = text;
    },
    lastRowPro(state, text){
      state.lastRowPro = text;
    },
    lastRowTie(state, text){
      state.lastRowTie = text;
    }
  },
  getters: {
    userName: state => state.userName,
    userRole: state => state.userRole,
    userAdmin: state => state.userAdmin,
    userRegion: state => state.userRegion,
    userEmail: state => state.userEmail,    

    lastRowInc: state => state.lastRowInc,
    lastRowPro: state => state.lastRowPro,
    lastRowTie: state => state.lastRowTie,
  }
})