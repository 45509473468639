<template>
    <div id="tiendasTemplate">
        <cat-generico 
        title = "Tiendas"
        :rows = 'tiendas' 
        :titles = 'titulos' 
        :columns = 'columnas'
        :totalRows = 'totalRows'        
        :perPage = 'perPage'   
        @search = 'buscar'     
        @newRecord = 'nuevo'
        @editRecord = 'editar'
        @deleteRecord = 'eliminar'
        @rowSelect = 'seleccionar'  
        @uploadFile = 'subirArchivo'
        @changeAvailablePage = 'cambiarPagina' 
        ></cat-generico>
        
        <!-- Modal Component -->
        <b-modal 
        ref="refEliminar"
        id="deleteRecord" 
        title="Catálogo de Tiendas"
        cancel-title = " Cancelar "
        ok-title=" Eliminar "
        @ok="borrar">
        <p class="my-4">Desea eliminar la tienda?</p>
        </b-modal>
    </div>
</template>

<script>
import axios from 'axios';
  import CatGenerico from '@/components/tools/CatGenericoTiendas.vue';

  export default {
    mounted: function() {
        this.getTablas()
      },
    components: {
      CatGenerico
    },
    data() {
      return {
        rowId: undefined,
        tiendas: [],
        tiendas2: [],
        titulos: ['ID', 'IATA', 'SUB IATA', 'SVP NOMBRE', 'CECO', 'DOMICILIO', 'NUM EXT', 'NUM INT',
        'COLONIA', 'CIUDAD', 'MUNICIPIO', 'ESTADO', 'CP', 'GERENCIA', 'ZONA'],        
        columnas: ['id','iataop', 'iatafalsa', 'svpname', 'ceco', 'domicilio', 'num_ext', 'num_int',
        'colonia', 'ciudad', 'municipio', 'estado', 'cp', 'gerencia', 'zona'],        
        totalRows: 450,
        currentPage: 1,
        perPage: 25,
        textoBuscar: ''
      }
    },
    methods: {
      fields(){
        var arr = [];
        for (let i in this.titulos) { 
          if( i*1 === 0 ){
            arr.push({'label': this.titulos[i], 'model': this.columnas[i], type: "input", inputType: "text", visible: false});              
          }else{
            if(this.titulos[i]==='IATA' || this.titulos[i] === 'SUB IATA'){
              arr.push({'label': this.titulos[i], 'model': this.columnas[i], type: "input", inputType: "text", max: 10});
            }else if(this.columnas[i] === 'zona'){              
              arr.push({'label': this.titulos[i], 'model': this.columnas[i], type: "select", values: this.zonas,
              selectOptions: {id: 'id', name: 'nombre', hideNoneSelectedText: true}});
            }else if(this.columnas[i] === 'ciudad'){              
              arr.push({'label': this.titulos[i], 'model': this.columnas[i], type: "select", values: this.ciudades,
              selectOptions: {id: 'id', name: 'nombre', hideNoneSelectedText: true}});  
                         
            } else {     
              arr.push({'label': this.titulos[i], 'model': this.columnas[i], type: "input", inputType: "text"});              
            }
          }
        }
        return arr;
      },
      getTablas() {
        var token =  this.$cookies.get('token');
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
        if(this.tiendas.length > 0){
          axios.all([
            this.getTiendas()
          ])
          .then(axios.spread((tiendasResponse) => {
            this.setTiendas(tiendasResponse);
          }))
        }else{
          axios.all([
            this.getZonas(),
            this.getCiudades(),
            this.getTiendas()
          ])
          .then(axios.spread((zonasResponse, ciudadesResponse, tiendasResponse) => {
            this.zonas = zonasResponse.data; 
            this.ciudades = ciudadesResponse.data; 
            this.setTiendas(tiendasResponse);
          }))
        }
      },
      setTiendas(tiendasResponse){
        this.tiendas2 = JSON.parse( JSON.stringify( tiendasResponse.data.content ) );
        this.tiendas = [];
        var items = tiendasResponse.data.content;              
        for (let item of items) {                
          item.zona = item.zonaa.nombre; 
          item.ciudad = item.ciudadd.nombre;
          this.tiendas.push(item)
        }
        this.totalRows = tiendasResponse.data.totalElements; 
      },
      getZonas() { return axios.get(process.env.VUE_APP_ROOT_API + "api/secure/zonas"); },
      getCiudades() {return axios.get(process.env.VUE_APP_ROOT_API + "api/secure/ciudades");},
      getTiendas: function() {
          var params = "?page=" + (this.currentPage - 1) + "&limit=" + this.perPage + "&searchtext=" + this.textoBuscar;
          return axios.get(process.env.VUE_APP_ROOT_API + "api/secure/tiendas" + params);          
      },

      buscar(texto){
          this.textoBuscar = texto;
          this.currentPage = 1;
          this.getTablas();
          
      },
      cambiarPagina(numPage){
          this.currentPage = numPage;
          this.getTablas();
      },
      seleccionar(row){            
        this.rowId = row.id;          
      },        
      nuevo: function(){
        this.$router.push({ name: 'Tienda', params: { id: this.selected, model: {}, fields: {fields: this.fields()}  }})                      
      },        
      editar: function(){
        if (!(this.rowId === undefined)){                   
          var tienda = this.tiendas2.find(row => row.id === this.rowId);
          this.$router.push({ name: 'Tienda', params: { id: this.rowId, model: tienda, fields: {fields: this.fields()}  }})            
          this.rowId = undefined;
        }else{
          alert('Debe seleccionar primero una tienda.');
        }
      },        
      eliminar: function() {
        if (!(this.rowId === undefined)){                          
          this.$refs.refEliminar.show();          
        }else{
          alert('Debe seleccionar primero una tienda.');
        }
      },
      borrar: function() {
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + this.$cookies.get('token');
        axios.delete(process.env.VUE_APP_ROOT_API + "api/secure/tiendas/" + this.rowId)
        .then((response) => {                      
          this.getTiendas();    
          this.rowId = undefined;      
        }, (error) => {          
        })        
      },
      subirArchivo(){
        this.$router.push({ name: 'TiendaUpload'})            
      }
    }
  }
</script>

<style>

    div#tiendasTemplate table{font-size:  1em}

    div#tiendasTemplate th:nth-child(1), div#tiendasTemplate td:nth-child(1) {display: none;} /* id */
    div#tiendasTemplate th:nth-child(2), div#tiendasTemplate td:nth-child(2) {min-width: 70px;} /* iata */
    div#tiendasTemplate th:nth-child(3), div#tiendasTemplate td:nth-child(3) {min-width: 70px;} /* sub-iata */
    div#tiendasTemplate th:nth-child(4), div#tiendasTemplate td:nth-child(4) {min-width: 200px;} /* svp_name */
    div#tiendasTemplate th:nth-child(6), div#tiendasTemplate td:nth-child(6) {min-width: 200px;} /* domicilio */

    div#tiendasTemplate th:nth-child(7), div#tiendasTemplate td:nth-child(7) {min-width: 70px;} /* domicilio */
    div#tiendasTemplate th:nth-child(8), div#tiendasTemplate td:nth-child(8) {min-width: 70px;} /* domicilio */

    div#tiendasTemplate th:nth-child( 9), div#tiendasTemplate td:nth-child( 9) {min-width: 150px;} /* domicilio */
    div#tiendasTemplate th:nth-child(10), div#tiendasTemplate td:nth-child(10) {min-width: 150px;} /* domicilio */
    div#tiendasTemplate th:nth-child(11), div#tiendasTemplate td:nth-child(11) {min-width: 150px;} /* domicilio */

    div#tiendasTemplate th:nth-child(12), div#tiendasTemplate td:nth-child(12) {min-width: 150px;} /* estado */

    div#tiendasTemplate th:nth-child(14), div#tiendasTemplate td:nth-child(14) {min-width: 150px;} /* domicilio */
    div#tiendasTemplate th:nth-child(15), div#tiendasTemplate td:nth-child(15) {min-width: 150px;} /* domicilio */
    div#tiendasTemplate th:nth-child(16), div#tiendasTemplate td:nth-child(16) {min-width: 150px;} /* domicilio */

    div.container{height: 600px;}

</style>