<template>
  <section class="dashboard-header">
    <div class="container-fluid">
      <div class="card-header">
        <h2>{{msg}}</h2>
        <div></div>
      </div>

      <div id="pago" class="row bg-white has-shadow my-3 pb-3">
        <div class="col-md-12">
          <div class="form mt-4 ml-2">
            

            <b-form-group label-cols-sm="4" label-cols-lg="3" label="Subir comprobante del pago : ">
              <b-row>
                <b-col class="col-9">
                  <b-form-file
                    class
                    v-model="file"
                    :state="Boolean(file)"
                    placeholder="Seleccionar un comprobante o soltar aqui..."
                    drop-placeholder="Soltar el archivo aqui..."
                    @change="previewImage"
                  ></b-form-file>
                  <div
                    class="mt-1 font-weight-medio"
                  >Comprobante seleccionado: {{ file ? file.name : '' }}</div>
                </b-col> 
                <b-col>
                  
                </b-col>               
              </b-row>

              <b-row>  
                <b-col class="col-9">
                  <h4 v-if="ver" class="mt-3">Tiendas agregadas a la base de datos.</h4>
                  <b-table small bordered  v-if="ver" class="mx-2 mt-2" :items="agregados" :fields="fields">
                    <template #cell(iata)="data">
                      {{ data.item.iatafalsa }} - {{ data.item.iataop }}
                    </template>
                  </b-table>  
                </b-col>
                
              </b-row>
            </b-form-group>

            <b-form-group label-cols-sm="4" label-cols-lg="3" label>
              <b-form inline class="mt-5">
                <b-btn class="float-right mr-5" variant="secondary" @click="regresar">Regresar</b-btn>                
                <b-btn class="float-right mr-3 ml-3" variant="primary" @click="procesar"><b-spinner small v-if="verSpinner"></b-spinner> Procesar</b-btn>
              </b-form>
            </b-form-group>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import axios from "axios";
export default {
  data(){
    return {
      msg: "Subir Tiendas en Excel",
      file: null,      
      imageData: "",
      imageExt: "",
      agregados: [],
      fields: [{ key: 'iata', label: 'iata' }, 'svpname'],
      ver: false,
      verSpinner: false
    }  
  },
  methods: {
    previewImage: function(event) {
      //this.imageData = "data:application/octet-stream;charset=utf-16le;base64,";
      var input = event.target;
      this.imageExt = input.files[0].type.split("/").pop();      
      for (let i = 0; i < input.files.length; i++) {
        var reader = new FileReader();
        reader.onload = e => {
          this.imageData = e.target.result;
        };
        reader.readAsDataURL(input.files[i]);
        
      }
    },
    procesar() {
      this.ver = false;
      this.verSpinner = true;
      let params = {comprobante: this.imageData};
      axios.defaults.headers.common["Authorization"] = "Bearer " + this.$cookies.get("token");
      axios.post(process.env.VUE_APP_ROOT_API + "api/secure/tiendas/subir", params)
        .then(response => {
          console.log(JSON.stringify(response.data));          
          this.agregados = response.data;
          this.ver = true;
          this.verSpinner = false;
        }, error => {
              this.verSpinner = false;
              console.log("Error: " + JSON.stringify(error.data));
        }
        
      );
    },
    regresar(){
      this.$router.push({ name: "Tiendas" });
    }
  }
}
</script>

<style>

</style>