<template>
  <div id="incidenciasTemplate">
    <cat-generico
      title="INCIDENCIAS"
      awesome="true"
      :rows="incidencias"
      :titles="titulos"
      :columns="columnas"
      :totalRows="totalRows"
      :perPage="perPage"
      @search="buscar"
      @newRecord="nuevo"
      @editRecord="editar"
      @deleteRecord="eliminar"
      @addImprimir="imprimir"
      @addSolicitud="solicitud"
      @addCotizacion="cotizacion"
      @addDocumentos="documentos"
      @addCarpeta="carpeta"
      @addPago="pago"
      @addPagoExt="pagoExt"
      @rowSelect="seleccionar"
      @changeAvailablePage="cambiarPagina"
    ></cat-generico>

    <!-- Modal Component -->
  </div>
</template>

<script>
import axios from "axios";
import VueMoment from "vue-moment";
import CatGenerico from "@/components/tools/CatGenerico.vue";

export default {
  mounted() {
    this.getTablas();
  },

  updated() {
    this.validar();
  },
  components: {
    CatGenerico,
  },
  data() {
    return {
      ponerClases: true,
      ver: this.$store.getters.userAdmin === "true",
      rowId: undefined,
      regiones: [
        { id: "XXXX", nombre: "NO ASIGNADA" },
        { id: "TSUR", nombre: "TICKETS SUR" },
        { id: "TBAJ", nombre: "TICKETS BAJIO" },
        { id: "TNOR", nombre: "TICKETS NORTE" },
        { id: "TPRO", nombre: "TICKETS PROYECTOS" },
      ],
      prioridades: [],
      zonas: [],
      ciudades: [],
      proveedores: [],
      incidencias2: [],
      incidencias: [],
      titulos: [
        "ID",
        "NOM REGION",
        "TICKET",
        "FECHA",
        "F. MOD",
        "FOLIO",
        "CIUDAD",
        "ESTADO",
        "IATA",
        "SUB IATA",
        "TIENDA",
        "ZONA",
        "DIRECCION",
        "USUARIO",
        "PRIORIDAD",
        "DIAS",
        "DESCRIPCION",
        "SERV. SOLICIT",
        "PROVEEDOR",
        "FECHA COT",
        "FECHA AUT",
        "FECHA CIERRE",
        "IMPORTE",
        "ANTICIPO",
        "IMP. CLIENTE",
        "ESTATUS",
        "A TIEMPO",
        "CARPETA",
        "OBSERVACIONES",
      ],
      columnas: [
        "id",
        "nomregion",
        "ticket",
        "fecha",
        "fechamod", //5
        "cotizacion",
        "ciudad",
        "estado",
        "iata",
        "subiata", //10
        "tienda",
        "region",
        "direccion",
        "usuario",
        "prioridad", //15
        "dias",
        "descripcion",
        "serviciosolicitado",
        "proveedor",
        "fechacotizacion", //20
        "fechaautorizacion",
        "fechacierre",
        "importe",
        "anticipo",
        "importedhl", //25
        "status",
        "atiempo",
        "carpeta",
        "observaciones",
      ],
      totalRows: 450,
      currentPage: 1,
      perPage: 19,
      textoBuscar: "ABIERTO",
      filtroBuscar: "",
    };
  },
  methods: {
    fields() {
      var arr = [];
      for (let i in this.titulos) {
        if (i * 1 === 0) {
          arr.push({
            label: this.titulos[i],
            model: this.columnas[i],
            type: "input",
            inputType: "text",
            visible: false,
          });
        } else if (this.columnas[i] === "nomregion") {
          arr.push({
            label: this.titulos[i],
            model: this.columnas[i],
            type: "select",
            values: this.regiones,
            selectOptions: {
              hideNoneSelectedText: true,
              id: "id",
              name: "nombre",
            },
          });
        } else {
          arr.push({
            label: this.titulos[i],
            model: this.columnas[i],
            type: "input",
            inputType: "text",
          });
        }
      }
      return arr;
    },
    getTablas() {
      this.ponerClases = true;
      var token = this.$cookies.get("token");
      axios.defaults.headers.common["Authorization"] = "Bearer " + token;
      if (this.incidencias.length > 0) {
        axios.all([this.getIncidencias()]).then(
          axios.spread((incidenciasResponse) => {
            this.progress = "Request finished";
            this.setIncidencias(incidenciasResponse);
          })
        );
      } else {
        axios
          .all([
            this.getPrioridades(),
            this.getZonas(), //or direct the axios request
            this.getCiudades(),
            this.getProveedores(),
            this.getIncidencias(),
          ])
          .then(
            axios.spread(
              (
                prioridadesResponse,
                zonasResponse,
                ciudadesResponse,
                proveedoresResponse,
                incidenciasResponse
              ) => {
                this.progress = "Request finished";
                this.prioridades = prioridadesResponse.data;
                this.zonas = zonasResponse.data;
                this.ciudades = ciudadesResponse.data;
                this.proveedores = proveedoresResponse.data.content;
                this.setIncidencias(incidenciasResponse);
                //console.log(incidenciasResponse.data);
              }
            )
          );
      }
    },
    setIncidencias(incidenciasResponse) {
      if (incidenciasResponse.data !== "") {
        this.incidencias2 = JSON.parse(
          JSON.stringify(incidenciasResponse.data.content)
        );
        this.incidencias = [];
        var items = incidenciasResponse.data.content;
        for (let item of items) {
          if (!(JSON.stringify(item.tienda) === "null")) {
            item.tiendaa = JSON.parse(
              JSON.stringify(item.tienda).replace("null", '""')
            );

            item.tienda = (item.tiendaa.svpname + "").toUpperCase();
            item.ciudad = (item.tiendaa.ciudadd.nombre + "").toUpperCase();
            item.estado = (item.tiendaa.ciudadd.estado + "").toUpperCase();
            item.region = (item.tiendaa.zonaa.nombre + "").toUpperCase();
            item.direccion = (
              item.tiendaa.domicilio +
              " " +
              item.tiendaa.num_ext +
              " COL. " +
              item.tiendaa.colonia
            ).toUpperCase();
          }
          if (!(JSON.stringify(item.proveedorr) === "null")) {
            item.proveedor = item.proveedorr.nombrecomercial;
          } else {
            item.proveedor = "NO ASIGNADO";
          }
          item.nomregion = this.regiones.find(
            (row) => row.id === item.nomregion
          ).nombre;

          item.importe = !item.importe ? 0 : item.importe;
          item.anticipo = !item.anticipo ? 0 : item.anticipo;

          this.incidencias.push(item);
        }
        this.totalRows = incidenciasResponse.data.totalElements;
      }else{
        this.incidencias = [];
        
        this.totalRows = 0;
      }
    },

    oso() {
      var rows = document.getElementById("tableCatGen").tBodies.item(0).rows;
      var longo = rows.length;
      var i = 0;
      for (i = 0; i < rows.length; i++) {
        var id = rows[i].getAttribute("id");
        var dias = this.incidencias.find((row) => row.id * 1 === id * 1).dias;
        var prioridad = this.incidencias.find((row) => row.id * 1 === id * 1)
          .prioridadd.prioridad;
        var fechaCierre = JSON.stringify(
          this.incidencias.find((row) => row.id * 1 === id * 1).fechacierre
        );

        var status = this.incidencias
          .find((row) => row.id * 1 === id * 1)
          .status.toUpperCase();

        if (
          fechaCierre !== "null" ||
          status === "TERMINADO" ||
          status === "CERRADO" ||
          status === "CANCELADO"
        ) {
          rows[i].className += " verde";
        } else {
          rows[i].classList.remove("verde");
          if (dias * 1 >= prioridad * 1) {
            rows[i].className += " rojo";
          } else {
            rows[i].classList.remove("rojo");
          }
        }
      }
      if (longo > 0) {
        this.ponerClases = false;
      }
    },

    validar() {
      if (!this.ver) {
        var rows = document.getElementById("tableCatGen").tBodies.item(0).rows;
        for (var i = 0; i < rows.length; i++) {
          rows[i].getElementsByTagName("td")[1].style.display = "none";
        }
        rows = document.getElementById("tableCatGen").tHead.rows;
        for (var i = 0; i < rows.length; i++) {
          rows[i].getElementsByTagName("th")[1].style.display = "none";
        }
      }

      if (this.ponerClases) {
        this.oso();
      }
    },
    getPrioridades() {
      return axios.get(process.env.VUE_APP_ROOT_API + "api/secure/prioridades");
    },
    getZonas() {
      return axios.get(process.env.VUE_APP_ROOT_API + "api/secure/zonas");
    },
    getCiudades() {
      return axios.get(process.env.VUE_APP_ROOT_API + "api/secure/ciudades");
    },
    getProveedores() {
      return axios.get(process.env.VUE_APP_ROOT_API + "api/secure/proveedores"); //?zona=" + this.incidencias.region);
    },
    getIncidencias: function () {
      var params =
        "?page=" +
        (this.currentPage - 1) +
        "&limit=" +
        this.perPage +
        "&searchtext=" +
        this.textoBuscar +
        "&filtro=" +
        this.filtroBuscar;

      //console.log(JSON.stringify(params))

      return axios.get(
        process.env.VUE_APP_ROOT_API + "api/secure/incidencias" + params
      );
    },
    buscar(texto, filtro) {
      this.filtroBuscar = filtro;
      this.textoBuscar = texto;
      this.currentPage = 1;
      this.getTablas();
    },
    cambiarPagina(numPage) {
      //console.log("chingaleeeeeeeeeeeee");
      //if( numPage ) {
      this.currentPage = numPage;
      this.getTablas();
      //}else{
      //this.currentPage = 1;
      //this.getTablas();
      //}
    },
    seleccionar(row) {
      this.ponerClases = true;
      this.rowId = row.id;
    },
    nuevo: function () {
      if (this.incidencias.length > 999999999999999999) {
        var incid = JSON.parse(JSON.stringify(this.incidencias[0]));
        for (var key in incid) {
          if (incid.hasOwnProperty(key)) {
            if (typeof incid[key] === "object") {
              var tienda = incid[key];
              for (var key2 in tienda) {
                if (tienda.hasOwnProperty(key2)) {
                  tienda[key] = null;
                }
              }
            } else {
              incid[key] = null;
            }
          }
        }
      } else {
        var incid = {};
        for (var key in this.columnas) {
          //var obj = {};
          incid[this.columnas[key]] = null;
          //incid.push(obj);
        }
        incid["iata"] = "ABB";
        incid["subiata"] = "ABB";
      }
      incid.nomregion = this.$store.getters.userRegion;
      incid.serviciosolicitado = "ATENDER";
      incid.prioridad = "R1";
      incid.status = "PENDIENTE";
      incid.idstatus = 5;
      incid.fecha = this.$moment().format("YYYY-MM-DD");
      this.$router.push({
        name: "Incidencia",
        params: {
          id: this.selected,
          model: incid,
          prioridades: this.prioridades,
          fields: { fields: this.fields() },
          vacio: true,
        },
      });
    },
    editar: function () {
      if (!(this.rowId === undefined)) {
        var incid = this.incidencias2.find((row) => row.id === this.rowId);

        //console.log(JSON.stringify(this.incidencias2.estatus));

        this.$router.push({
          name: "Incidencia",
          params: {
            id: this.rowId,
            model: incid,
            prioridades: this.prioridades,
            fields: { fields: this.fields() },
          },
        });
        this.rowId = undefined;
      } else {
        alert("Debe seleccionar primero una incidencia.");
      }
    },
    eliminar: function () {
      var id = this.incidencias.find((row) => row.id === this.rowId).id;
      if (!(this.rowId === undefined)) {
        this.$refs.refEliminar.show();
      } else {
        alert("Debe seleccionar primero una incidencia. ");
      }
    },
    borrar: function () {
      axios.defaults.headers.common["Authorization"] =
        "Bearer " + this.$cookies.get("token");
      axios
        .delete(
          process.env.VUE_APP_ROOT_API + "api/secure/incidencias/" + this.rowId
        )
        .then(
          (response) => {
            this.getTablas();
            this.rowId = undefined;
          },
          (error) => {}
        );
    },
    solicitud() {
      if (!(this.rowId === undefined)) {
        var id = this.incidencias.find((row) => row.id === this.rowId).id * 1;
        var incid = this.incidencias2.find((row) => row.id === this.rowId);
        if (JSON.stringify(incid.proveedorr) !== "null") {
          axios.defaults.headers.common["Authorization"] =
            "Bearer " + this.$cookies.get("token");
          axios
            .post(
              process.env.VUE_APP_ROOT_API + "api/secure/incidencias/solicitud",
              incid
            )
            .then(
              (response) => {
                var params = {
                  id: id,
                  pdf: response.data.pdf,
                  folio: incid.cotizacion,
                  nameProv: incid.proveedorr.nombrecomercial,
                  emailProv: incid.proveedorr.email,
                };

                this.$router.push({ name: "Solicitud", params: params });
                this.rowId = undefined;
              },
              (error) => {}
            );
        } else {
          alert("Debe asignar un proveedor.");
        }
      } else {
        alert("Debe seleccionar una incidencia.");
      }
    },
    cotizacion: function () {
      if (!(this.rowId === undefined)) {
        var id = this.incidencias.find((row) => row.id === this.rowId).id * 1;
        var incid = this.incidencias2.find((row) => row.id === this.rowId);
        this.$router.push({
          name: "Cotizacion",
          params: {
            id: id,
            incid: incid,
          },
        });

        this.rowId = undefined;
      } else {
        alert("Debe seleccionar primero una incidencia.");
      }
    },
    documentos: function () {
      if (!(this.rowId === undefined)) {
        var id = this.incidencias.find((row) => row.id === this.rowId).id * 1;
        this.$router.push({ name: "Documento", params: { id: id } });
        this.rowId = undefined;
      } else {
        alert("Debe seleccionar primero una incidencia.");
      }
    },
    carpeta: function () {
      if (this.rowId === undefined) {
        var incidencia = this.incidencias2[0];
      } else {
        var incidencia = this.incidencias2.find((row) => row.id === this.rowId);
      }
      this.$router.push({
        name: "Carpeta",
        params: {
          numCarpeta: incidencia.carpeta,
          fechaCarpeta: incidencia.fecha,
          region: incidencia.nomregion,
        },
      });
      this.rowId = undefined;
    },
    imprimir() {
      if (this.rowId === undefined) {
        var incidencia = this.incidencias2[0];
      } else {
        var incidencia = this.incidencias2.find((row) => row.id === this.rowId);
      }
      this.$router.push({
        name: "Imprimir",
        params: { fechaCarpeta: incidencia.fecha, region: incidencia.region },
      });
      this.rowId = undefined;
    },
    pagoExt() {
      const params = { capturarTicket: "Si", id: "1", region: "ADMN" };
      this.$router.push({ name: "PagoInt", params: params });
      this.rowId = undefined;
    },
    pago() {
      console.log("perros");

      if (!(this.rowId === undefined)) {
        const incid = this.incidencias.find((row) => row.id === this.rowId);

        console.log(JSON.stringify(incid));

        const params = {
          id: incid.id,
          region: incid.nomregion,
          ticket: incid.ticket,
          sucursal: incid.iata + "-" + incid.subiata,
          tiendasubiata: incid.subiata,
          localidad: incid.ciudad + ", " + incid.estado,
        };

        console.log(JSON.stringify(params));

        this.$router.push({ name: "Pago", params: params });
        this.rowId = undefined;
      } else {
        alert("Debe seleccionar primero una incidencia.");
      }
    },
  },
};
</script>

<style>
.page-item.active .page-link {
  background-color: #000033 !important;
  border-color: #353679 !important;
}

div#incidenciasTemplate thead tr {
  background-color: lightgray;
}
tr.rojo {
  color: red;
}
tr.verde {
  color: green;
}

div#incidenciasTemplate th:nth-child(1),
div#incidenciasTemplate td:nth-child(1) {
  display: none;
} /* id */

div#incidenciasTemplate th:nth-child(2),
div#incidenciasTemplate td:nth-child(2) {
  min-width: 120px;
} /* nomregion */

div#incidenciasTemplate th:nth-child(4),
div#incidenciasTemplate td:nth-child(4) {
  min-width: 100px;
} /* fecha */

div#incidenciasTemplate th:nth-child(5),
div#incidenciasTemplate td:nth-child(5) {
  min-width: 100px;
} /* fechamod */

div#incidenciasTemplate th:nth-child(6),
div#incidenciasTemplate td:nth-child(6) {
  min-width: 150px;
} /* cotizac */

div#incidenciasTemplate th:nth-child(7),
div#incidenciasTemplate td:nth-child(7) {
  min-width: 150px;
} /* ciudad */
div#incidenciasTemplate th:nth-child(8),
div#incidenciasTemplate td:nth-child(8) {
  min-width: 150px;
} /* estado */

div#incidenciasTemplate th:nth-child(9),
div#incidenciasTemplate td:nth-child(9) {
  min-width: 50px;
} /* iata */
div#incidenciasTemplate th:nth-child(10),
div#incidenciasTemplate td:nth-child(10) {
  min-width: 70px;
} /* sub iata */

div#incidenciasTemplate th:nth-child(11),
div#incidenciasTemplate td:nth-child(11) {
  min-width: 120px;
} /* svp nme */

div#incidenciasTemplate th:nth-child(12),
div#incidenciasTemplate td:nth-child(12) {
  display: none;
} /* zona */

div#incidenciasTemplate th:nth-child(13),
div#incidenciasTemplate td:nth-child(13) {
  min-width: 350px;
} /* domicilio */
div#incidenciasTemplate th:nth-child(14),
div#incidenciasTemplate td:nth-child(14) {
  min-width: 180px;
} /* usuario */
div#incidenciasTemplate th:nth-child(15),
div#incidenciasTemplate td:nth-child(15) {
  min-width: 70px;
} /* prioridad */

div#incidenciasTemplate th:nth-child(16),
div#incidenciasTemplate td:nth-child(16) {
  min-width: 70px;
} /* dias */

div#incidenciasTemplate th:nth-child(17),
div#incidenciasTemplate td:nth-child(17) {
  min-width: 600px;
} /* descripcion */

div#incidenciasTemplate th:nth-child(18),
div#incidenciasTemplate td:nth-child(18) {
  min-width: 100px;
} /* srv solic */

div#incidenciasTemplate th:nth-child(19),
div#incidenciasTemplate td:nth-child(19) {
  min-width: 200px;
} /* proveedor */

div#incidenciasTemplate th:nth-child(20),
div#incidenciasTemplate td:nth-child(20) {
  min-width: 120px;
} /* fecha */
div#incidenciasTemplate th:nth-child(21),
div#incidenciasTemplate td:nth-child(21) {
  min-width: 120px;
} /* fecha */

div#incidenciasTemplate th:nth-child(22),
div#incidenciasTemplate td:nth-child(22) {
  min-width: 120px;
} /* fecha cierre*/

div#incidenciasTemplate th:nth-child(23),
div#incidenciasTemplate td:nth-child(23) {
  min-width: 100px;
} /* importe */

div#incidenciasTemplate th:nth-child(24),
div#incidenciasTemplate td:nth-child(24) {
  min-width: 100px;
} /* anticipo */

div#incidenciasTemplate th:nth-child(25),
div#incidenciasTemplate td:nth-child(25) {
  min-width: 100px;
} /* importe dhl */

div#incidenciasTemplate th:nth-child(26),
div#incidenciasTemplate td:nth-child(26) {
  min-width: 120px;
} /* estatus */

div#incidenciasTemplate th:nth-child(27),
div#incidenciasTemplate td:nth-child(27) {
  min-width: 120px;
} /* a tiempo */

div#incidenciasTemplate th:nth-child(28),
div#incidenciasTemplate td:nth-child(28) {
  min-width: 100px;
} /* carpeta */

div#incidenciasTemplate th:nth-child(29),
div#incidenciasTemplate td:nth-child(29) {
  min-width: 450px;
} /* observaciones */

div.container {
  height: 600px;
}
</style>