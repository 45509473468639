<template>
    <section class="dashboard-header">
        <div class="container-fluid">  
            <div class="card-header">
                <h2>{{msg}}</h2>
                <div>   
                </div>
            </div>

            <div class="row">  
                <div class="col-lg-12 col-12 mt-2 mb-0">
                    <ul class="nav justify-content-end float-right">
                        <li class="nav-item">
                        <a class="nav-link fa fa-plus-square-o" href="#" @click="newLine" title="Agregar"></a>
                        </li>                        
                        <li class="nav-item">
                        <a class="nav-link fa fa-minus-square-o" href="#" @click="deleteLine" title="Eliminar"></a>
                        </li>                        
                    </ul>
                </div>
            </div>



            <div id="cotizacion" class="bg-white has-shadow pt-4 pb-4">
                
                <div class="row">
                    <div class="col-md-8 offset-md-1">
                        <table class="table table-bordered table-sm">
                            <tr><td>Fecha</td><td>{{fecha}}</td></tr>
                            <tr><td>Ticket</td><td>{{this.ticket}}</td></tr>
                            <tr><td colspan="2" align="center">Sucursal:</td></tr>                            
                            <tr><td>Sucursal</td><td>{{this.iata.svpname}}</td></tr>
                            <tr><td>Dirección</td><td>{{getDomicilio()}}</td></tr>
                            <tr><td>Solicitante</td><td>INFRAESTRUCTURA DHL</td></tr>
                        </table>
                    </div>
                </div>

                <div class="row">
                    <div class="col-md-10 offset-md-1">
                        <table id="tablaconceptos" class="table table-bordered table-sm">
                            <thead>
                                <tr>
                                    <td>PARTIDA</td>                                                                                                    
                                    <td>DESCRIPCION</td>                                                                       
                                    <td>UNIDAD</td>                                
                                    <td>CANTIDAD</td>
                                    <td>COSTO U</td>
                                    <td>IMPORTE</td>
                                </tr>
                            </thead>

                            <tbody>
                                <tr v-for="art in arts" :class="{active: art.id == selected}" @click="selected = art.id">
                                    <!-- <td><medium-editor :text= "art.part" v-on:edit='editPart'></medium-editor custom-tag='p'></td> -->                                    
                                    <!-- <td class="nums"><medium-editor :text= "art.cant" v-on:edit='editCant' custom-tag='p'></medium-editor></td>
                                    <td><medium-editor :text= "art.desc" v-on:edit='editDesc'></medium-editor custom-tag='p'></td>
                                    <td><medium-editor :text= "art.unidad" v-on:edit='editUnidad'></medium-editor custom-tag='p'></td>
                                    <td class="nums"><medium-editor :text= "art.costo" v-on:edit='editCosto' custom-tag='p'></medium-editor></td>
                                    <td class="nums">{{ art.importe }}</td> -->

                                    <td class="concepto"><input class="form-control form-control-success" type="text" v-model="art.part"></td>                                    
                                    <td class="conceptodesc"><input class="form-control" type="text" v-model="art.desc"></td>
                                    <td class="concepto"><input class="form-control" type="text" v-model="art.unidad"></td>
                                    <td class="conceptonum"><input class="form-control" type="text" v-model.number="art.cant" v-on:change="onChange($event)"></td>
                                    <td class="conceptonum"><input class="form-control" type="text" v-model.number="art.costo" v-on:change="onChange($event)"></td>
                                    <td class="conceptonum"><input class="form-control" align="right" type="text" v-model="art.importe" readonly></td>
                                </tr>                                
                            </tbody>                            

                            <tfoot>
                                <tr>
                                    <td colspan="4">OBSERVACIONES</td>                                    
                                    <td>SUBTOTAL</td>
                                    <td class="nums form-control" >{{subTotal}}</td>
                                </tr>
                                <tr>                                    
                                    <td colspan="4" rowspan="4">
                                        <textarea class="observaciones" rows="6">{{observaciones}}</textarea>                                        
                                    </td>
                                    <td>DESCUENTO</td>
                                    <td class="conceptonum">
                                        <input class="form-control" type="text" v-model.number="descuento" v-on:change="onChange($event)">
                                    </td>                                    
                                </tr>
                                <tr>                                    
                                    <td>NETO</td>
                                    <td class="nums form-control" >{{neto}}</td>
                                </tr>
                                <tr>                                    
                                    <td>                                        
                                        <select name="account" class="form-control" @change="onChangeSel($event)" v-model="tazaiva">
                                            <option value="0.16">IVA 16%</option>
                                            <option value="0.08">IVA 8%</option>
                                        </select>
                                    </td>
                                    <td class="nums form-control" >{{iva}}</td>
                                </tr>
                                <tr>                                    
                                    <td>VALOR TOTAL</td>
                                    <td class="nums form-control" >{{valorTotal}}</td>
                                </tr>
                                <tr>                                    
                                    <td colspan="2">VIGENCIA</td>
                                    <td colspan="2" ref="vigencia">{{ fecha | moment("add", "30 days","YYYY-MM-DD") }}</td>
                                </tr>
                            </tfoot>
                        </table>
                    </div>
                </div>

                <div class="row">
                    <div class="offset-md-8 col-md-4 col-4 mt-2 mb-0">
                        <b-btn class="mr-3 ml-3 mt-3" variant="primary" @click="grabar">Grabar</b-btn>
                        <b-btn class="mr-3 ml-3 mt-3" variant="secundary" @click="regresar">Regresar</b-btn>
                    </div>
                </div>

            </div>
        </div>

    </section>
</template>

<script>
import axios from 'axios';
export default {
    name:"cotizacion-form",    
    mounted: function() {
        this.getDatos();
    },
    data() {
        return {    
            //emailProv: this.$route.params.emailProv,
            //ticket: this.$route.params.ticket,
            pdf: '',                    
            tabla: 'cotizaciones',
            msg: "Cotización",
            id: this.$route.params.id,          
            subTotal: '0.00',
            descuento: '0.00',
            neto: '0.00',
            tazaiva: '0.16',
            iva: '0.00',
            valorTotal: '0.00',  
            observaciones: '', 
            selected: undefined,  
            incid: this.$route.params.incid,          
            fecha: this.$route.params.incid.fecha, 
            iata: this.$route.params.incid.tienda,
            ticket: this.$route.params.incid.ticket,
            arts: [
                { id: 1, part: '', desc: '', unidad: '', cant: '1', costo: '0.00', importe: '0.00' }
                ],
            text: ""
        }
    },
    components: {
        
    },
    computed: {        
    },
    methods:{    
        onChangeSel(event) {
            this.onChange(event);
        }, 
        onChange(event){
            var ars = this.arts;            
            var subTot = 0.00;
            var desc = 0.00;
            var net = 0.00;
            var iv = 0.00;
            var valTot = 0.00;
            var tazaIva = this.tazaiva * 1;
            for (var i in ars){
                var cant = ars[i].cant * 1;
                var costo = ars[i].costo * 1;                
                subTot = subTot + (cant * costo); 
                ars[i].importe = (cant * costo).toFixed(2);
            }                        
            desc = this.descuento * 1;
            net = subTot - desc;
            iv = (net * tazaIva).toFixed(2) * 1;
            valTot = net + iv;
            this.subTotal = subTot.toFixed(2);
            this.neto = net.toFixed(2);
            this.iva = iv.toFixed(2);
            this.valorTotal = valTot.toFixed(2);
        },
        getDatos(){
            if( !(JSON.stringify(this.incid.conceptos) === undefined) && !(JSON.stringify(this.incid.conceptos) === "null") ){
                var cons = JSON.parse(atob(this.incid.conceptos)).conceptos;   
                this.arts = cons.arts;
                this.tazaiva = cons.tazaiva;
                this.onChange();
            }
        },
        getDomicilio(){
            return  (this.iata.domicilio + " " + 
                    this.iata.num_ext + " " + 
                    this.iata.num_int + " " + 
                    this.iata.colonia + ". " +
                    this.iata.ciudadd.nombre + ", " + 
                    this.iata.ciudadd.estado + " (C.P. " + this.iata.cp + ")").toUpperCase();
        },               
        grabar(){
            var cotizacion = {
                id: this.id, 
                conceptos: JSON.stringify({arts: this.arts, tazaiva: this.tazaiva}),
                subtotal: this.subTotal,
                descuento: this.descuento,
                neto: this.neto,
                iva: this.iva,
                total: this.valorTotal,
                vigencia: this.$refs.vigencia.textContent,
                observaciones: this.observaciones};
            console.log(JSON.stringify(cotizacion));
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + this.$cookies.get('token');
            axios.post(process.env.VUE_APP_ROOT_API + "api/secure/incidencias/cotizacion", cotizacion)
            .then((response) => {         
                this.incid.conceptos = response.data.conceptos;
                this.$router.push({ name: 'Cotizacionpdf', params: { id: this.id, pdf: response.data.pdf, incid: this.incid}});
            }, (error) => {
            //console.log("Error: " + JSON.stringify( error) );
            })                        
            
        },
        regresar(){
            this.$router.replace({ path: '/portal/incidencias' });
        },
        newLine(){
            this.arts.push({ id: this.arts.length+1, part: '', desc: '', unidad: '' , cant: '1', costo: '0', importe: '0.00' });
        },        
        deleteLine(){
            console.log(this.selected)
            var i;              
            for (i in this.arts) {
                if(this.arts[i].id === this.selected){
                    this.arts.splice(i,1);
                    break;
                }                
            }
        }
    }
}
</script>

<style>
  section.dashboard-header{padding: 20px 0;}
  tr.active{background: #f2f2f2}
  p.blank-line{font-size: 1.1em; margin-bottom: 5px}
  p.text-line{font-size: 1em; margin-bottom: 5px}

  td{padding-bottom: 5px !important;}

  table#tablaconceptos td.concepto{width: 16%}
  table#tablaconceptos td.conceptodesc{width: 20%}
  table#tablaconceptos td.conceptonum{width: 16%;}

  table#tablaconceptos td.conceptonum input{text-align: right}

  table#tablaconceptos td.nums{text-align: right; padding-right: 17px;}

  table#tablaconceptos tr td textarea.observaciones{width: 100%}
  /*;text-align: right;}*/

  .nav-link {
      padding: 0.5rem 0.7rem;
  }

  iframe{width: 95%; margin-left: 2.5%; height: 97.5%;}
  
  div.container{height: 600px;}


</style>
