// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from 'vue'
import BootstrapVue from "bootstrap-vue"
import App from './App'
import router from './router'
import VueCookies from 'vue-cookies'
import { store } from './store/store'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import VueMoment from 'vue-moment';



require("./assets/vendor/font-awesome/css/font-awesome.min.css")
require("./assets/css/fontastic.css")
require("./assets/css/style.default.css")
require("./assets/css/custom.css")



Vue.use(BootstrapVue)
Vue.use(VueCookies)

const moment = require('moment')
require('moment/locale/es')
Vue.use(require('vue-moment'), {
  moment
})

// Add a request interceptor
import axios from 'axios';
axios.interceptors.request.use(function (config) {
  // Do something before request is sent  
  return config;
}, function (error) {
  // Do something with request error  
  return Promise.reject(error);
});
// Add a response interceptor
axios.interceptors.response.use(function (response) {
  // Do something with response data 
  window.$cookies.set("token", window.$cookies.get("token"), process.env.VUE_APP_SESSION_TIMEOUT);
  return response;
}, function (error) {
  // Do something with response error
  if(error.response.status === 401){
    router.replace({ path: 'login' })
  }
  return Promise.reject(error);
});

/*new Vue({
  el: '#app',
  router,
  store,
  template: '<App/>',
  components: { App }  
})*/



new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')

