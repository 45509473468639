import Vue from 'vue'
import Router from 'vue-router'
// Portañ
import PortalLayout from '@/components/portal/PortalLayout.vue'
import Inicio from '@/components/portal/views/Inicio'
import Proveedores from '@/components/portal/views/Proveedores'
import Usuarios from '@/components/portal/views/usuarios/Usuarios'
import Incidencia from '@/components/portal/views/Incidencia'
import Incidencias from '@/components/portal/views/Incidencias'
import Pagos from '@/components/portal/views/Pagos'
import Pago from '@/components/portal/views/Pago'
import PagoFechas from '@/components/portal/views/PagoFechas'
import PagoCheck from '@/components/portal/views/PagoCheck'
import PagoCheckInt from '@/components/portal/views/PagoCheckInt'
import PagoPagar from '@/components/portal/views/PagoPagar'
import PagoInt from '@/components/portal/views/PagoInt'
import PagoPagarInt from '@/components/portal/views/PagoPagarInt'
import PagoImprimir from '@/components/portal/views/PagoImprimir'
import Programados from '@/components/portal/views/programados/Programados'
import Cotizacion from '@/components/portal/views/Cotizacion'
import Regiones from '@/components/portal/views/tablas/Regiones'
import Region from '@/components/portal/views/tablas/Region'
import Zonas from '@/components/portal/views/tablas/Zonas'
import Zona from '@/components/portal/views/tablas/Zona'
import Tiendas from '@/components/portal/views/Tiendas'
import TiendaUpload from '@/components/portal/views/TiendaUpload'


// Sitio
import Login from '@/components/sitio/Login'

import { store } from '@/store/store.js'

Vue.use(Router)

const router = new Router({
  routes: [
    { path: '/', component: PortalLayout, redirect: '/portal' },
    {
      path: '/portal', component: PortalLayout, meta: { admin: true }, redirect: '/portal/inicio',
      children: [
        { path: 'inicio', name: 'Inicio', component: Inicio },
        { path: 'proveedores', name: 'Proveedores', component: Proveedores },
        { path: 'proveedor', name: 'Proveedor', component: () => import('@/components/portal/views/Proveedor.vue') },
        { path: 'usuarios', name: 'Usuarios', component: Usuarios },
        { path: 'usuario', name: 'Usuario', component: () => import('@/components/portal/views/usuarios/Usuario.vue') },
        { path: 'perfil', name: 'Perfil', component: () => import('@/components/portal/views/Perfil.vue') },
        { path: 'incidencia', name: 'Incidencia', component: Incidencia },
        { path: 'incidencias', name: 'Incidencias', component: Incidencias },
        { path: 'pagos', name: 'Pagos', component: Pagos },
        { path: 'pago', name: 'Pago', component: Pago },
        { path: 'pagofechas', name: 'PagoFechas', component: PagoFechas},
        { path: 'pagocheck', name: 'PagoCheck', component: PagoCheck },
        { path: 'pagocheckint', name: 'PagoCheckInt', component: PagoCheckInt },
        { path: 'pagoPagar', name: 'PagoPagar', component: PagoPagar },
        { path: 'pagoInt', name: 'PagoInt', component: PagoInt },
        { path: 'pagoPagarInt', name: 'PagoPagarInt', component: PagoPagarInt },
        { path: 'pagoImprimir', name: 'PagoImprimir', component: PagoImprimir },
        { path: 'programados', name: 'Programados', component: Programados },
        { path: 'solicitud', name: 'Solicitud', component: () => import('@/components/portal/views/Solicitud.vue') },
        { path: 'cotizacion', name: 'Cotizacion', component: Cotizacion },
        { path: 'cotizacionpdf', name: 'Cotizacionpdf', component: () => import('@/components/portal/views/CotizacionPdf.vue') },
        { path: 'documento', name: 'Documento', component: () => import('@/components/portal/views/Documento.vue') },
        { path: 'carpeta', name: 'Carpeta', component: () => import('@/components/portal/views/Carpeta.vue') },
        { path: 'Imprimir', name: 'Imprimir', component: () => import('@/components/portal/views/Imprimir.vue') },
        { path: 'regiones', name: 'Regiones', component: Regiones },
        { path: 'region', name: 'Region', component: Region },
        { path: 'zonas', name: 'Zonas', component: Zonas },
        { path: 'zona/:id', name: 'Zona', component: Zona },
        { path: 'tiendas', name: 'Tiendas', component: Tiendas },
        { path: 'tiendaUpload', name: 'TiendaUpload', component: TiendaUpload },
        { path: 'tienda', name: 'Tienda', component: () => import('@/components/portal/views/Tienda.vue') },
        { path: 'roles', name: 'Roles', component: () => import('@/components/portal/views/tablas/Roles.vue') },
        { path: 'rol', name: 'Rol', component: () => import('@/components/portal/views/tablas/Rol.vue') },

        { path: 'prioridades', name: 'Prioridades', component: () => import('@/components/portal/views/tablas/Prioridades.vue') },
        { path: 'prioridad', name: 'Prioridad', component: () => import('@/components/portal/views/tablas/Prioridad.vue') },

        { path: 'ciudades', name: 'Ciudades', component: () => import('@/components/portal/views/tablas/Ciudades.vue') },
        { path: 'ciudad', name: 'Ciudad', component: () => import('@/components/portal/views/tablas/Ciudad.vue') },

        { path: 'noexiste', name: 'Noexiste', component: () => import('@/components/portal/views/Noexiste.vue') }
      ]
    },
    { path: '/login', name: 'Login', component: Login },
    { path: '*', redirect: '/portal/noexiste' }
  ]
})

router.beforeEach((to, from, next) => {
  var checarToken = to.matched.some(record => record.meta.admin);
  var isTokenValid = !(window.$cookies.get("token") === null);
  if (checarToken && isTokenValid) {
    var token = window.$cookies.get("token");
    //window.$cookies.set("token", token, "30min");
    if (!store.getters.userName) {
      store.commit('usuario', token)
    }

    window.$cookies.set("token", token, process.env.SESSION_TIMEOUT);
  }

  //console.log("From: " + from.name + " -> " + to.name + " ChecarToken: " + checarToken + " isTokenValid: " + isTokenValid + ""  + store.getters.userRol );

  if (checarToken) {
    if (isTokenValid) {
      
      if (
        to.name.toLocaleLowerCase().includes('usuario') ||
        to.name.toLocaleLowerCase() === 'pagoCheck' ||
        to.name.toLocaleLowerCase() === 'pagoPagar'        
        // || to.name.toLocaleLowerCase().includes('proveedor')
      ) {
        if (store.getters.userAdmin === 'true') {
          next();
        }
      } else {
        next();
      }


    } else {
      next('/login');
    }
  } else {
    next();
  }



})


export default router;
