<template>
  <div class="page">
    
    <div class="navbar-header">  
      <top-navbar></top-navbar>
    </div>  

    <div class="page-content d-flex align-items-stretch">

      <content-sidebar-admin v-if="checarRol('adm')"></content-sidebar-admin>

      <content-sidebar-limit v-if="checarRol('proy')"></content-sidebar-limit>

      <content-sidebar-user v-if="checarRol('usr')"></content-sidebar-user>

      

      <div v-bind:class="[isSidebarActive ? 'content-inner' : 'content-inner active']">
        <!-- <header class="page-header">
          <div class="container-fluid">
            <h2 class="no-margin-bottom">{{routeName}}</h2>
          </div>
        </header> -->
      
        <dashboard-content></dashboard-content>  
            
        <content-footer></content-footer>

      </div>
    </div>
  </div>
      
</template>
<style scoped> 
  div.content-inner{min-height: calc(100vh - 52px); padding-bottom: 30px;}
</style>
<script>
  import TopNavbar from './PortalNavbar.vue'
  import ContentFooter from './PortalFooter.vue'
  import DashboardContent from './Content.vue'  
  import ContentSidebarAdmin from './sidebar/sidebarAdmin.vue'
  import ContentSidebarUser from './sidebar/sidebarUser.vue'
  import ContentSidebarLimit from './sidebar/sidebarLimitada.vue'
    
  export default {
    data(){
      return {        
        isSidebarActive: true,
      }
    },
    components: {      
      TopNavbar,
      ContentFooter,
      DashboardContent,
      ContentSidebarAdmin,
      ContentSidebarUser,
      ContentSidebarLimit
    },
    methods: {
      checarRol(tipo){
        console.log(this.$store.getters.userRole);
        var ret = false;
        if(tipo === "adm"){
          if(this.$store.getters.userRole !== 'Usuario' && this.$store.getters.userRole !== 'Proyectos'){
            ret = true;
          }
        }else if(tipo === "proy"){
          if(this.$store.getters.userRole === 'Proyectos'){
            ret = true;
          }
        }else{
          if(this.$store.getters.userRole === 'Usuario'){
            ret = true;
          }
        }
        return ret
      }
    },
    computed: {
      routeName () {
        const {name} = this.$route
        return name.toUpperCase()
      }
    },
  }

</script>
<style scoped>  
</style>