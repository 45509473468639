<template>
  <div id="proveedoresTemplate">    
    <cat-generico
      title="Proveedores"
      awesome="false"      
      :items="proveedores"
      :fields="columnas"
      :totalRows="totalRows"
      :perPage="perPage"
      :showDatosBanco="showDatosBanco"
      @search="buscar"
      @newRecord="nuevo"
      @editRecord="editar"
      @deleteRecord="eliminar"
      @verDatosBanco="verDatosBanco"
      @ocultarDatosBanco="ocultarDatosBanco"
      @addImprimir="imprimir"
      @changeAvailablePage="cambiarPagina"
      @rowSelected="seleccionado"
    ></cat-generico>


    <b-modal
        ref="refEliminar"
        title="Catálogo de Proveedores"
        cancel-title=" Cancelar "
        ok-title=" Eliminar "
        @ok="borrar"
        >
        <p class="my-4">Desea eliminar el proveedor {{rowId}}?</p>
    </b-modal>
  </div>
</template>

<script>
import axios from "axios";
import VueMoment from "vue-moment";
import CatGenerico from "@/components/tools/CatProveedores.vue";
export default {
  components: {
    CatGenerico
  },
  mounted() {
    this.getTablas();
  },
  data() {
    return {      
      proveedores: [],
      columnas: [
        "id",
        "codigo",
        "nombrecomercial",
        "contacto",
        "telefono",
        "email",
        //"banco",
        //"cuenta",
        //"tarjeta",
        //"clabe",
        //"titular",
        { key: "ciudadd", label: "localidad" }
      ],
      showDatosBanco: false,
      totalRows: 0,
      currentPage: 1,
      perPage: 18,
      textoBuscar: "",
      filtroBuscar: "",
      selected: [],
      rowId: null
    };
  },
  methods: {
    async getTablas() {
      var params =
        "?page=" +
        (this.currentPage - 1) +
        "&limit=" +
        this.perPage +
        "&searchtext=" +
        this.textoBuscar +
        "&filtro=" +
        this.filtroBuscar;
      var resp = await axios.get(
        process.env.VUE_APP_ROOT_API + "api/secure/proveedores/" + params
      );
      //console.log(JSON.stringify(resp.data));
      this.totalRows = resp.data.totalElements;
      this.proveedores = resp.data.content;
      //this.textoBuscar = "";
    },
    nuevo(items) {
      this.$router.push({ name: "Proveedor", params: { model: null } });      
    },
    buscar(texto) {
      this.textoBuscar = texto;
      this.currentPage = 1;
      this.getTablas();
    },
    editar(items) {
      if (items.length === 1) {
        this.$router.push({ name: "Proveedor", params: { model: items[0] } });
      } else {
        alert("Debe seleccionar primero un proveedor.");
      }
    },
    eliminar() {
      this.$refs.refEliminar.show();
    },
    borrar(){
      axios.defaults.headers.common["Authorization"] = "Bearer " + this.$cookies.get("token");
            axios.delete(process.env.VUE_APP_ROOT_API + "api/secure/proveedores/" + this.rowId)
            .then(
                response => {
                  this.textoBuscar = "";
                  this.getTablas();
                },
                error => {

                }
            );
    },
    verDatosBanco(items) {
      if (items.length === 1) {
        this.showDatosBanco = !this.showDatosBanco;        
      } else {
        alert("Debe seleccionar primero un proveedor.");
      }
    },
    ocultarDatosBanco(items) {
      this.showDatosBanco = false;
    },
    imprimir() {},
    cambiarPagina(numPage) {
      this.currentPage = numPage;
      this.getTablas();
    },
    seleccionado(items) {
      this.rowId = items[0].id;
    }
  }
};
</script>

<style>
</style>

