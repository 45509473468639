<template>
  <section class="dashboard-header">
    <div class="container-fluid">  
      <div class="card-header">
        <h3>Tabla de {{title}}</h3>
        <div>   
        </div>
  
      </div>
  
      <div class="row">
  
        <div class="col-lg-6 col-6 mt-2 mb-1">
          <div class="form-inline">
            <form>           
              <input class="form-control mr-sm-2" type="search" aria-label="Search" name="query" v-model="searchQuery">        
            </form>
            <!-- <a class="nav-link fa fa-search" href="#" title="Buscar"></a>   -->
          </div>
        </div>
  
        <div class="col-lg-6 col-6 mt-2 mb-0">
          <ul class="nav justify-content-end">

            <li class="nav-item">
              <a class="nav-link fa fa-plus-square-o" href="#" @click="newRecord" title="Agregar"></a>
            </li>
            <li class="nav-item">
              <a class="nav-link fa fa-pencil-square-o" href="#" @click="editRecord" title="Modificar"></a>
            </li>
            <li class="nav-item">
              <a class="nav-link fa fa-minus-square-o" href="#" title="Eliminar" v-b-modal.modal1></a>
            </li>

          </ul>
        </div>
      </div>
  
      <div class="contenido row bg-white has-shadow small">
        <div class="statistics col-lg-5 col-5">

          <b-table striped hover @row-clicked="rowSelected" :items="filteredData" :fields="titles"></b-table>
  
          
  
        </div>
      </div>

    </div>  

  </section>
</template>

<script>

export default {  
  data() {
    return {
      selected: undefined,
      searchQuery: ''
    }
  },
  //name: 'selectedx',
  props: [
    'title', 'titles',
    'rows'
  ],
  /*computed: {
    selectedx() {
      return this.selected;
    }
  },*/
  
  computed: {
      filteredData: function () {
        let filterKey = this.searchQuery && this.searchQuery.toLowerCase();
        let data = this.rows;
        if (filterKey) {
            data = data.filter(function (row) {
              return Object.keys(row).some(function (key) {
                  return String(row[key]).toLowerCase().indexOf(filterKey) > -1;
              })
            })
        }  
        this.items = data;
        //console.log(JSON.stringify(this.items));
        return data;
      },
  },
  methods: {    
    rowSelected(item, index, event){
      this.selected = index
      this.$emit('rowSelect', item)
    },
    findRecord() {
      this.$emit('findRecord')
    },
    newRecord() {
      this.$emit('newRecord')
    },
    editRecord() {
      this.$emit('editRecord')
    }
  }
}
</script>

<style scoped>
div.contenido{height: 75vh;}
  a {
  color: #000033 !important;
}
  section.dashboard-header {
    padding: 20px 0;
  }
  table#tableZonas tr.active{background: #edffa8}
  table#tableZonas tr:hover{background: #eff5cc}
</style>
