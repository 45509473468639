<template>
  <section class="dashboard-header">
    <div class="container-fluid">
      <div class="card-header">
        <h3>Tabla de {{ title }}</h3>
        <div></div>
      </div>

      <div class="row">
        <div class="barra col-lg-6 col-6 mt-2 mb-1">
          <div class="form-inline">
            <input
              class="form-control mr-sm-2 col-4"
              type="search"
              aria-label="Search"
              v-on:keyup.enter="search"
              v-model="textoBuscar"
              v-if="awesome === 'false'"
            />
            <a
              class="nav-link fa fa-search"
              href="#"
              @click="search"
              title="Buscar"
              v-if="awesome === 'false'"
            ></a>

            <b-form-select
              v-model="filtro"
              v-if="awesome === 'false'"
              @change="onChange($event)"
              class="ml-3 col-4"
            >
              <option value="todo">Ver todo</option>              
              <option value="Si">Con factura</option>
              <option value="No">Sin factura</option>
              <option value="eliminados" v-if="$store.getters.userAdmin === 'true'">Eliminados</option>
              <option value="1 Recibido">1 Recibido</option>
              <option value="2 Revisado">2 Revisados</option>
              <option value="3 Pagar">3 Autorizados</option>
              <option value="4 Finalizado">4 Pagados</option>
            </b-form-select>
            <div>
            <a
                class="nav-link opciones1 fa fa-calendar ml-2"
                href="#"
                @click="datesForm"
                :title="kokok"
              ></a>
            </div>
            <a
              class="nav-link fa fa-search"
              href="#"
              @click="search"
              title="Filtrar"
              v-if="awesome === 'true'"
            ></a>
          </div>
        </div>

        <div class="barra col-lg-2 col-2 mt-2 mb-1">
          <b-pagination
            align="center"
            size="sm"
            :limit="5"
            :total-rows="totalRows"
            v-model="currentPage"
            :per-page="perPage"
            @input="changeAvailablePage"
          />
        </div>

        <div class="col-lg-3 col-3 mt-2 mb-0">
          <ul class="nav justify-content-end">

            <li class="nav-item" v-if="$store.getters.userRole !== 'Asistente'">
              <a
                class="nav-link opciones1 fa fa-plus-square-o mr-2"
                href="#"
                @click="newPago"
                title="Pago interno"
              ></a>
            </li>

            <li class="nav-item" v-if="$store.getters.userRole !== 'Asistente'">
              <a
                class="nav-link opciones1 fa fa-caret-square-o-up mr-1"
                href="#"
                @click="programarPago"
                title="Programar pago"
              ></a>
            </li>


            

            <li class="nav-item" v-if="$store.getters.userAdmin === 'true'">
              <a
                class="nav-link opciones1 fa fa-check-square-o"
                href="#"
                @click="newRecord"
                title="Revisar"
              ></a>
            </li>
            <li class="nav-item" v-if="$store.getters.userAdmin === 'true'">
              <a
                class="nav-link opciones1 fa fa-calendar-check-o"
                href="#"
                @click="editRecord"
                title="Autorizar"
              ></a>
            </li>
           
            <li class="nav-item" >
              <a
                class="nav-link opciones1 fa fa-dollar"
                href="#"
                @click="addPago"
                title="Pagar"
              ></a>
            </li>

            <!-- <li class="nav-item">
              <a
                class="nav-link opciones1 fa fa-dollar"
                href="#"
                @click="deleteRecord"
                title="Eliminar"
              ></a>
            </li>-->
            <li class="nav-item" v-if="$store.getters.userAdmin === 'true'">
              <a
                class="nav-link opciones2 fa fa-file-image-o"
                href="#"
                @click="addDocumentos"
                title="Documentos"
              ></a>
            </li>

            <li class="nav-item" v-if="$store.getters.userAdmin === 'true'">
              <a
                class="nav-link opciones1 fa fa-minus-square-o"
                href="#"
                @click="deleteRecord"
                title="Eliminar"
              ></a>
            </li>

            
              &emsp;
              <a
                class="nav-link opciones2 fa fa-print"
                href="#"
                @click="addImprimir"
                title="Imprimir"
              ></a>
            

            <!-- <li class="nav-item" v-if="awesome === 'false'">
              &emsp;<a class="nav-link opciones2 fa fa-envelope-o" href="#" @click="sendEmail" title="Enviar correo"></a>              
            </li>-->

            <li class="nav-item ml-3" v-if="awesome === 'true'"> 
              <a
                class="nav-link ver fa fa-chevron-left"
                href="#"
                @click="verMenos"
                title="verMas"
              ></a>
              <a
                class="nav-link ver fa fa-chevron-right"
                href="#"
                @click="verMas"
                title="verMas"
              ></a>
            </li>
          </ul>
        </div>
      </div>

      <div id="fondoTabla" class="row bg-white has-shadow small">
        <div class="contenidoTabla col-lg-12 col-12">
          <b-table

            v-model="visibleRows"

            hover
            selectable
            :select-mode="selectMode"
            @row-selected="onRowSelected"
            :items="items"
            :fields="fields"
          >
            <template v-slot:cell(cantidad)="row">{{ 
              parseFloat(row.item.cantidad).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,') 
            }}</template>
            
            <template v-slot:cell(proveedorr)="row">{{
              row.value.nombrecomercial
            }}</template>

            <template v-slot:cell(contacto)="row">{{
              row.item.proveedorr.contacto
            }}</template>

            <template v-slot:cell(tienda)="row"
              >{{ row.item.tienda.svpname }}</template
            >

            <template v-slot:cell(fecha)="row"
              >{{ row.item.fecha }} {{ row.item.hora }}</template
            >

            <template v-slot:cell(urgencia)="row">
              {{row.item.urgencia >= 3 ? 'No': 'Si'}}</template
            >

            <template v-slot:cell(usuario)="row">{{
              row.value.name ? (row.item.status === '1 Recibido' || row.item.status === '2 Revisado' ? '' : row.value.name.substring(15, 0) ) : ""
            }}</template>

            <template v-slot:cell(tiendaciudad)="row"
              >{{ row.item.tienda.ciudadd.nombre.toUpperCase() }},
              {{ row.item.tienda.ciudadd.estado.toUpperCase() }}</template
            >
            <!-- <template v-slot:cell(pago)="row"
              >{{ row.item.fechapago }}
              {{
                row.item.turno + "" === "null"
                  ? ""
                  : "" + row.item.turno.substring(0, 1).toUpperCase()
              }}</template
            > -->
            <template v-slot:cell(status)="row">{{
              row.value.substring(0, 5)
            }}</template>

            <template v-slot:custom-foot="data">
              <b-tr>
                <b-td colspan="2"></b-td>
                <b-td>${{importeTotal}} {{data.dummy}}</b-td>
              </b-tr>
            </template>
          </b-table>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  data() {
    return {
      dummy: "",
      filtro: "todo",
      filtroval: "todo",
      selected: [],
      textoBuscar: "",
      searchQuery: "",
      currentPage: 1,
      selectMode: "single",
      visibleRows: [],      
      fechas: "ososososo"
    }
  },
  props: ["title", "awesome", "items", "fields", "totalRows", "perPage", "desde", "hasta"],
  mounted() {    
  },
  computed: {    
    kokok(){
      return this.desde + " | " + this.hasta;   
    },
    importeTotal() {      
      let suma = this.visibleRows.reduce((accum, item) => { return accum + parseFloat(item.cantidad) }, 0.0)
      return (parseFloat(suma).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,'))
    }
  },
  methods: {
    onChange(event) {

      this.filtroval = this.filtro;

      this.$emit("onChange", event);
    },
    search() {
      this.$emit("search", this.textoBuscar, this.filtro);
    },

    changeAvailablePage(numPage) {
      this.currentPage = numPage;
      this.$emit("changeAvailablePage", numPage);
    },
    onRowSelected(items) {
      this.selected = items;
      this.$emit("rowSelected", this.selected);
    },
    datesForm(xx, zz) {
      this.fechas = this.desde + " | " + this.hasta;
      this.$emit("datesForm");
    },
    setFechaDesdeHasta(){
      this.fechas = this.desde + " - " + this.hasta;
      this.$emit("setFechaDesdeHasta");
    },
    rowSelect(row) {
      this.selected = row.id;
      //console.log("select: " + this.selected);
      this.$emit("rowSelect", row);
    },
    selectRow(row) {
      this.selected = row.id;
      //console.log("chicho2: " + this.selected);
      //this.$refs.myTable.selectRow(7);
      this.$emit("selectRow", row);
    },
    rowSelected() {
      this.$emit("rowSelected", this.selected);
    },
    findRecord() {
      this.$emit("findRecord");
    },
    programarPago(){
      this.$emit("programarPago", this.selected);
    },
    newPago() {
      this.$emit("newPago", this.selected);
    },
    newRecord() {
      this.$emit("newRecord", this.selected);
    },
    editRecord() {
      this.$emit("editRecord", this.selected);
    },
    deleteRecord() {
      this.$emit("deleteRecord");
    },
    addImprimir() {
      this.$emit("addImprimir", this.selected);
    },
    addPago() {
      this.$emit("addPago", this.selected);
    },
    addSolicitud() {
      this.$emit("addSolicitud");
    },
    addCotizacion() {
      this.$emit("addCotizacion");
    },
    addDocumentos() {
      this.$emit("addDocumentos");
    },
    addCarpeta() {
      this.$emit("addCarpeta");
    },
    verMenos() {
      var ele = document.querySelector("div.contenidoTabla");
      var pos = ele.scrollLeft - 500;
      ele.scrollTo(pos, 0);
    },
    verMas() {
      var ele = document.querySelector("div.contenidoTabla");
      var pos = ele.scrollLeft + 500;
      ele.scrollTo(pos, 0);
    },
  },
};
</script>

<style scoped>
a {
  color: #000033 !important;
}

section.dashboard-header {
  padding: 20px 0;
}

div#fondoTabla {
  height: 100%;
}

/* tr:hover{background-color: #d1ceeb ; font-weight: 500; color: black} */
tr:hover {
  background-color: rgb(236, 231, 250);
}
tr.active {
  background-color: #a49be9;
  font-weight: 500;
  color: black;
}

div.contenidoTabla {
  overflow-x: scroll;
}
a.nav-link.opciones1 {
  padding: 0.4rem 0.3rem;
}
a.nav-link.opciones2 {
  padding: 0.4rem 0.3rem;
}
a.nav-link.ver {
  padding: 0.5rem 0rem;
}
</style>
