<template>
  <div>
    <cat-generico
      title="PAGOS"
      awesome="false"
      :items="pagos"
      :fields="columnas"
      :totalRows="totalRows"
      :perPage="perPage"
      @regresar="regresar"
      @search="buscar"
      @changeAvailablePage="cambiarPagina"
      @rowSelected="seleccionar"
    ></cat-generico>
  </div>
</template>

<script>
import axios from "axios";
import CatGenerico from "@/components/tools/CatProgramados.vue";
import * as dayjs from 'dayjs'
export default {
  components: {
    CatGenerico,
  },
  created() {},
  mounted() {
    this.getTablas();
  },
  data() {
    return {
      pagos: [],
      columnas: [
        "id",
        "ticket",
        "cantidad",
        "factura",
        { key: "proveedorr", label: "Proveedor" },
        { key: "contacto", label: "Contacto" },
        { key: "tienda", label: "Sucursal" },
        //{ key: "tiendaciudad", label: "Localidad" },
        { key: "usuario", label: "Autorizó" },
        "region",
        "concepto",
        { key: "fecha", label: "Fecha" },
        { key: "fecharev", label: "FRev" },
        { key: "fechaaut", label: "FAut" },
        { key: "fechapago", label: "FPag" },
        "tipo",
        { key: "urgencia", label: "Urgente" },
        //{ key: "pago", label: "Pago" },
        { key: "status", label: "Estatus" },
      ],
      totalRows: 450,
      currentPage: 1,
      perPage: 30,
      textoBuscar: "",
      filtroBuscar: "",
    };
  },
  methods: {
    async getTablas() {
      var params = "?page=" + (this.currentPage) + "&limit=" + this.perPage + "&searchtext=" + this.textoBuscar;

      console.log(process.env.VUE_APP_ROOT_API + "api/secure/pagos/listaprogs" + params );

      var resp = await axios.get(process.env.VUE_APP_ROOT_API + "api/secure/pagos/listaprogs" + params);

      let items = resp.data;

      //console.log(JSON.stringify(resp.data));

      items.forEach(function (item) {
        if (item.status === "2 Revisado") {
          item["_rowVariant"] = "warning";
        }
        if (item.status === "3 Pagar") {
          item["_rowVariant"] = "success";
        }
      });

      console.log("largo: ", resp.data.length);

      this.pagos = resp.data;
      this.totalRows = resp.data.length;
    },
    async regresar(items){
      if(items.length === 1){
        var resp = await axios.post(process.env.VUE_APP_ROOT_API + "api/secure/pagos/desprogramar",{id: items[0].id});        
        this.getTablas();
      }
    },
    buscar(texto, filtro) {
      this.textoBuscar = texto;  
      this.getTablas();          
    },
    cambiarPagina(numPage) {
      this.currentPage = numPage;
      this.getTablas();
    },
    seleccionar(row) {
      if (row.length > 0) {
        this.rowId = row[0].id;
      } else {
      }
    },
  },
};
</script>

<style>
.row-vencida{color: red !important;}
.row-por-vencer{color: green; font-weight: bold;}
</style>