<template>
  <section class="dashboard-header">
    <div class="container-fluid">
      <div class="card-header">
        <h2>{{msg}} ticket: {{model.ticket}}</h2>       
        <div></div>
      </div>
      <div id="pago" class="row bg-white has-shadow my-3 pb-3">
        <div class="col-md-12">
          <div class="form mt-4 ml-2">
            <b-form-group label-cols-sm="4" label-cols-lg="3" label="Proveedor: ">
              <span>{{nameProveedor}}</span>
            </b-form-group>

            <b-form-group label-cols-sm="4" label-cols-lg="3" label="Correo Electrónico proveedor: " >
              <b-form-input class="w-40" v-model="model.email"></b-form-input>
            </b-form-group>

            <b-form-group label-cols-sm="4" label-cols-lg="3" label="Mensaje o instrucciones: ">
              <b-form-textarea rows="3" max-rows="10" v-model="model.mensaje"></b-form-textarea>
            </b-form-group>

            <b-form-group label-cols-sm="4" label-cols-lg="3" label="Subir comprobante del pago : ">
              <b-row>
                <b-col class="col-6">
                  <b-form-file
                    class
                    v-model="file"
                    :state="Boolean(file)"
                    placeholder="Seleccionar un comprobante o soltar aqui..."
                    drop-placeholder="Soltar el archivo aqui..."
                    @change="previewImage"
                  ></b-form-file>
                  <div
                    class="mt-1 font-weight-medio"
                  >Comprobante seleccionado: {{ file ? file.name : '' }}</div>
                </b-col>
                <b-col class="col-6">
                  <select class="form-control" v-model="nameComprobante" @change="onChange" size="0">
                    <option v-for="comprobante in comprobantes" v-bind:key="comprobante.id" :value="comprobante.nombre">{{ comprobante.fecha }}</option>            
                  </select>
                  
                  <img id="container" v-bind:src="imagePaste" width="100%"/>
                  <img v-bind:src="imageData" width="100%" v-if="imageExt !== 'pdf'"/>
                  <object class="w-100" style="height: 20rem" :data="imageData" type="application/pdf" v-else>
                    alt :
                    <a href="test.pdf">test.pdf</a>
                  </object>
                </b-col>
              </b-row>
            </b-form-group>

            <b-form-group label-cols-sm="4" label-cols-lg="3" label>
              <b-form inline class="mt-5">
                <b-btn class="float-right mr-5" variant="secondary" @click="regresar">Regresar</b-btn>
                <b-form-checkbox :disabled="proyecto" v-model="isEliminarImage" class=" ml-1"></b-form-checkbox>
                <b-btn class="float-right mr-5" variant="light" @click="eliminarComprobante()" :disabled="proyecto|| !isEliminarImage">Eliminar imagen</b-btn>
                <b-btn class="float-right mr-5" variant="light" @click="procesar(true)">Solo grabar</b-btn>
                <b-form-checkbox
                  v-model="isValidar"
                  class="mb-2 ml-1 mr-1"
                >Registrar y enviar email</b-form-checkbox>
                <b-btn
                  class="float-right mr-3 ml-3"
                  variant="primary"
                  @click="procesar(false)"
                  :disabled="!isValidar"
                >Procesar</b-btn>
              </b-form>
            </b-form-group>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import axios from "axios";

document.onpaste = function(pasteEvent) {
  var item = pasteEvent.clipboardData.items[0];

  if (item.type.indexOf("image") === 0) {
    var blob = item.getAsFile();

    var reader = new FileReader();
    reader.onload = function(event) {
      document.getElementById("container").src = event.target.result;
    };

    reader.readAsDataURL(blob);
  }
}

export default {
  
  data() {
    return {
      file: null,
      cfdi: null,
      isValidar: false,
      imagePaste: "",
      imageData: "",
      imageCfdi: "",
      imageExt: "",
      pdfData: "",      
      pdfCfdi: "",
      cfdiExt: "",
      nameComprobante: "",
      isEliminarImage: false,
      msg: "Realizar el pago a proveedor",
      turno: [
        { value: "mañana", text: "Por la mañana" },
        { value: "tarde", text: "Por la tarde" }
      ],
      tiendasubiata:
        this.$route.params.tienda.iataop +
        "-" +
        this.$route.params.tienda.iatafalsa,
      model: this.$route.params,
      proyecto: this.$route.params.proyecto === "S" ? true : false,
      estatus: this.$route.params.status,
      nameProveedor:
        this.$route.params.proveedorr.nombrecomercial +
        " / " +
        this.$route.params.proveedorr.contacto +
        " / " +
        this.$route.params.tienda.ciudadd.nombre +
        ", " +
        this.$route.params.tienda.ciudadd.estado,
      emailProveedor: this.$route.params.proveedorr.email
      ,oso: "OKOKOKOKOKOKO",
      comprobantes: []
    };
  },
  mounted() {    
    if(JSON.stringify(this.model.email) === "null"){
      if(JSON.stringify(this.model.proveedorr.email) !== "null"){
        console.log("email proveedor se asigno a email pago")
        this.model.email = this.model.proveedorr.email
      }
    } 
    
    if(this.$store.getters.userAdmin === "true"){
      this.proyecto = false;
    }

    this.getComprobantes();    
  },
  methods: {
    limpiarImagen(){  
      let img = document.getElementById("container");
      img.src = "";
      this.imagePaste = "";
      this.imageData = "";
    },
    onChange(e){
      this.getComprobante(e.target.value);
    },
    getComprobantes(){
      axios.defaults.headers.common["Authorization"] = "Bearer " + this.$cookies.get("token");
      axios.post(process.env.VUE_APP_ROOT_API + "api/secure/pagos/comprobantes",{id: this.model.id, ticket: this.model.ticket})
        .then(
          response => {
             this.comprobantes = response.data;
             if(response.data.length>0){
              this.nameComprobante = this.comprobantes[0].nombre;
              this.getComprobante(this.comprobantes[0].nombre);
             }
          },
          error => {
            console.log("Error: " + JSON.stringify(error.data));
          }
        );
    },
    getComprobante(image){
      this.isEliminarImage = false;
      let re = /(?:\.([^.]+))?$/;
      let ext = re.exec(image)[1]
      this.imageExt = ext;
      axios.defaults.headers.common["Authorization"] = "Bearer " + this.$cookies.get("token");
      axios.post(process.env.VUE_APP_ROOT_API + "api/secure/pagos/comprobante",{"image": image})
        .then(
          response => {
            if(ext === 'pdf'){
              this.imagePaste = "";
              this.imageData = "data:application/pdf;base64," + response.data;
            }else{
              this.imageData = "";
              this.imagePaste = "data:image/jpeg;base64," + response.data;
            }
            
             
          },
          error => {
            console.log("Error: " + JSON.stringify(error.data));
          }
        );
    },
    eliminarComprobante(){
      this.isEliminarImage = false;
      axios.defaults.headers.common["Authorization"] = "Bearer " + this.$cookies.get("token");
      axios.post(process.env.VUE_APP_ROOT_API + "api/secure/pagos/eliminarcomprobante",{"name": this.nameComprobante})
        .then(
          response => {
            this.imagePaste = "";
            this.imageData = "";
            this.getComprobantes();
          },
          error => {
            console.log("Error: " + JSON.stringify(error.data));
          }
        );
    },
    osito(){
      //console.log(JSON.stringify(this.model));
      //this.getBase64Paste();
      //this.imageData = this.imagePaste;
    },
    getBase64Paste(){      
      var img = document.getElementById("container");
      if(!img.src || !img.src.length || img.src.length === 0){
        this.imagePaste = "";
      }else{
        var c = document.createElement("canvas");//.getElementById('myCanvas');
        c.width = img.naturalWidth;
        c.height = img.naturalHeight;
        var ctx = c.getContext("2d");
        ctx.drawImage(img, 0, 0);
        this.imagePaste = c.toDataURL();
      }
    }, 
    previewImage: function(event) {
      this.imagePaste = "";
      this.imageData = "data:image/jpeg;base64,";
      var input = event.target;
      this.imageExt = input.files[0].type.split("/").pop();
      if (this.imageExt === "pdf") {
        this.imageData = "data:application/pdf;base64,";
      }
      for (let i = 0; i < input.files.length; i++) {
        var reader = new FileReader();
        reader.onload = e => {
          this.imageData = e.target.result;
        };
        reader.readAsDataURL(input.files[i]);
      }
    },

    previewCfdi: function(event) {
      this.imageCfdi = "data:image/jpeg;base64,";
      var input = event.target;
      this.cfdiExt = input.files[0].type.split("/").pop();
      if (this.cfdiExt === "pdf") {
        this.imageCfdi = "data:application/pdf;base64,";
      }
      for (let i = 0; i < input.files.length; i++) {
        var reader = new FileReader();
        reader.onload = e => {
          this.imageCfdi = e.target.result;
        };
        reader.readAsDataURL(input.files[i]);
      }
    },

    regresar() {
      this.$router.push({ name: "Pagos" });
      //this.$router.replace({ path: '/portal/pagos' });
    },

    procesar(noEnviar = false) {
      
      this.model.status = "4 Finalizado";
      this.model.fechapago = this.$moment().format("YYYY-MM-DD");    
      this.getBase64Paste();      

      let params = {
        pago: this.model,        
        emailProveedor: this.emailProveedor
      };

      //console.log(this.imagePaste, this.imagePaste.length);

      if(this.imagePaste.length > 10){
        params.comprobante = this.imagePaste;
        params.nombre = "xxxx.jpg";
      }else{
        params.comprobante = this.imageData;
        params.nombre = this.file.name;
      }

      if ( JSON.stringify(this.model.mensaje) === "null" ) {
        params.pago.mensaje = "";
      }      
      params.noEnviar = noEnviar;
      axios.defaults.headers.common["Authorization"] =
        "Bearer " + this.$cookies.get("token");
      axios
        .post(process.env.VUE_APP_ROOT_API + "api/secure/pagos/pagar", params)
        .then(
          response => {
            this.$router.push({ name: "Pagos" });
          },
          error => {
            console.log("Error: " + JSON.stringify(error.data));
          }
        );
    }
  }
};
</script>

<style>
.limpiarImagen{  
  position:absolute;
  right: 2rem;
  top: 6rem;
  width: 5%;
  max-width:5%;
}



</style>