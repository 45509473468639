<template>
  <div>
    <tabla-generica 
    title = "Regiones"
    :rows = 'regiones' 
    :titles = 'titulos' 
    :columns = 'columnas'    
    @newRecord = 'nuevo'
    @editRecord = 'editar'
    @rowSelect = 'seleccionar'   ></tabla-generica>
    
    <!-- Modal Component -->
    <b-modal 
    id="modal1" 
    title="Tabla de Regiones"
    cancel-title = " No "
    ok-title=" Si "
    @ok="borrar">
      <p class="my-4">Desea eliminar la region?</p>
    </b-modal>
  </div>
</template>

<script>
  import axios from 'axios';
  import TablaGenerica from '@/components/tools/TablaGenerica.vue';

  export default {
    mounted: function() {
        this.getRegiones()
      },
    components: {
      TablaGenerica
    },
    data() {
      return {
        rowId: undefined,
        regiones: [],
        titulos: ['ID', 'NOMBRE', 'NOMBRE CORTO', 'CORREO ELECTRONICO'],        
        columnas: ['id', 'nombre', 'nomcorto', 'email'],        
        textoBuscar: 'sdfsdfsdfsdfsdfsdfsdfsd'
      }
    },
    methods: {
      getRegiones: function() {
          var token =  this.$cookies.get('token');
          axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
          axios.get(process.env.VUE_APP_ROOT_API + "api/secure/regiones")
            .then((response) => {              
              this.regiones = response.data
            }, (error) => {              
  
            })
        },
        seleccionar(row){
          this.rowId = row.id;          
        },
        nuevo: function(){
          this.$router.push({ name: 'Region', params: { id: 0, region: {id: null, nombre: null, email: null, usuario: null}  }})            
        },
        editar: function(){
          if (!(this.rowId === undefined)){     
            this.$router.push({ name: 'Region', params: { id: this.rowId, region: this.regiones.find(row => row.id === this.rowId)  }})            
          }
        },        
        borrar: function() {
          if (!(this.rowId === undefined)){                 
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + this.$cookies.get('token');
            axios.delete(process.env.VUE_APP_ROOT_API + "api/secure/regiones/" + this.rowId)
            .then((response) => {                      
              this.getRoles();
            }, (error) => {          
            })
          }
        },
    }
  }
</script>

<style>  
</style>