<template>
  <section class="dashboard-header">
    <div class="container-fluid">
      <div class="card-header">
        <h2>{{msg}} ticket int: {{model.ticket}}</h2>
        <div></div>
      </div>

      <div id="pago" class="row bg-white has-shadow my-3 pb-3">
        <div class="col-md-10">
          <div class="form mt-4 ml-2">
            <b-form-group label-cols-sm="4" label-cols-lg="3" label="Factura ?: ">
              <b-form-select :options="factura" class="w-50" v-model="model.factura"></b-form-select>
            </b-form-group>  

            <b-form-group label-cols-sm="4" label-cols-lg="3" label="Referencia: " v-if="capturarTicket==='Si'">

              <b-row>
                <b-col class="col-5">
                  <input type="text" class="form-control w-100" v-model="model.ticket" >
                </b-col>
                <b-col class="col-7">
                  
                    <b-form-file                                        
                      v-if="model.factura==='Si'"
                      v-model="file"
                      :state="Boolean(file)"
                      placeholder="Buscar comprobante..."
                      drop-placeholder="Soltar el archivo aqui..."
                      @change="previewImage"
                    ></b-form-file>
                    <div class="mt-1 font-weight-medio" v-if="model.factura==='Si'">{{ file ? file.name : '' }}</div>
                  
                </b-col>
              </b-row>
              
            </b-form-group>

            <b-form-group label-cols-sm="4" label-cols-lg="3" label="Proveedor: ">
              <b-row>
                <b-col class="col-8">
                  <vue-autosuggest
                    v-model="query"
                    :suggestions="suggestions"
                    :input-props="{id:'autosuggest__input', name: 'rufus', ref: 'cojon', class: 'form-control w-100'}"                  
                    @input="fetchProveedores"
                    @selected="selectHandler"
                    @click="clickHandler"
                  >  
                    <div slot-scope="{suggestion}" style="display: flex; align-items: center;" class="w-100">                  
                    <div>{{suggestion.item.nombrecomercial}} ({{suggestion.item.titular}}) {{suggestion.item.ciudad}}</div>
                    </div>
                  </vue-autosuggest>
                </b-col>
                <b-col class="col-4">
                  <i class="nav-link opciones1 fa fa fa-plus-square-o mr-4"
                    href="#"
                    @click="abrirAltaRapidaProveedor"
                    title="Alta rápida proveedor"
                  >
                  </i>                  
                </b-col>
              </b-row>                          
              <span>{{localidad}}</span>
            </b-form-group>     
            
            <b-form-group label-cols-sm="4" label-cols-lg="3" label="Sucursal: ">              
              <input type="text" class="form-control w-60" v-model="sucursal" disabled>              
            </b-form-group>
            
            <b-form-group label-cols-sm="4" label-cols-lg="3" label="Concepto: ">              
              <textarea id="conceptos" class="form-control" rows="3" max-rows="10" v-model="model.concepto"></textarea>
            </b-form-group>

            <b-form-group label-cols-sm="4" label-cols-lg="3" label="Fecha: ">
              <input type="date" class="form-control w-50" v-model="model.fecha">
            </b-form-group>

            <b-form-group label-cols-sm="4" label-cols-lg="3" label="Cantidad: ">
              <input type="text" class="form-control w-40" v-model="model.cantidad">
            </b-form-group>

            <b-form-group label-cols-sm="4" label-cols-lg="3" label="Tipo: ">
              <b-form-select :options="tipos" class="w-30" v-model="model.tipo"></b-form-select>
            </b-form-group>

            <b-form-group label-cols-sm="4" label-cols-lg="3" label="Urgente: " v-if="capturarTicket!=='No'">
              <b-form-select :options="urgencias" class="w-30" v-model="model.urgencia"></b-form-select>
            </b-form-group>

            <b-btn class="float-right mr-3 ml-3 mt-3" variant="secundary" @click="regresar">Regresar</b-btn>
            <b-btn class="float-right mr-3 ml-3 mt-3" variant="primary" @click="agregarPago">Grabar</b-btn>
          </div>

          <b-modal ref="altaRapidadProv" title="Agregar Proveedor Rápido" @ok="altaRapidaProveedor">
                  <div>
                    <b-form-group label-cols="4" label-cols-lg="4" label="Codigo Giro">
                      <b-form-select v-model="codigo" :options="giros" class="w-100"></b-form-select>
                    </b-form-group>

                    <b-form-group
                      label-cols="4"
                      label-cols-lg="4"
                      label="Nombre Proveedor"
                      label-for="nombre"
                    >
                      <b-form-input id="nombre" v-model="nombre">{{query}}</b-form-input>
                    </b-form-group>
                    <b-form-group
                      label-cols="4"
                      label-cols-lg="4"
                      label="Ciudad"
                      label-for="ciudad"
                    >
                      <b-form-select v-model="ciudad" :options="options"></b-form-select>
                    </b-form-group>

                    <b-form-group
                      label-cols="4"
                      label-cols-lg="4"
                      label="Telefono"
                      label-for="telefono"
                    >
                      <b-form-input id="telefono" v-model="telefono"></b-form-input>
                    </b-form-group>

                    <b-form-group
                      label-cols="4"
                      label-cols-lg="4"
                      label="Correo Electronico"
                      label-for="email"
                    >
                      <b-form-input id="email" v-model="email"></b-form-input>
                    </b-form-group>
                  </div>
                </b-modal>


        </div>
      </div>
    </div>
  </section>
</template>

<script>
import axios from "axios";
import {VueAutosuggest} from "vue-autosuggest";
import {parseString} from "xml2js";
import * as dayjs from 'dayjs';

export default {
  mounted() {
    //console.log("sososo: ", this.$route.params.tiendasubiata);
    this.getUser();
  },
  components: {
    VueAutosuggest
  },
  data() {
    return {
      file: null,
      imageData: "",
      suggestions: [],
      suggestion: [],

      
      //this.provSelected = item.item;
      //this.localidad = item.item.ciudad + ", " + item.item.estado;
      //this.query = item.item.nombrecomercial;      
      //this.model.proveedor = item.item.id;
      //this.model.ciudad = item.item.idciudad;
      //this.suggestions = [];
      //this.provSelected = null;  


      query: (this.$route.params.capturarTicket === 'Pay' ? this.$route.params.data.proveedorr.nombrecomercial : "0"),
      selected: "",
      provSelected: "",

      msg: "Agregar solicitud de pago",
      localidad: (this.$route.params.capturarTicket === 'Pay' ? this.$route.params.data.proveedorr.ciudadd.nombre + "," + this.$route.params.data.proveedorr.ciudadd.estado : ""),        
      sucursal: (this.$route.params.capturarTicket === 'Si' ? 'CEN-AAA' : this.$route.params.tiendasubiata),      
      capturarTicket: "Si", 
      model: {        
        //idusuario: '',
        idincidencia: this.$route.params.id,
        ticket: "",
        region: this.$route.params.region,
        cantidad: (this.$route.params.capturarTicket === 'Pay' ? this.$route.params.data.cantidad : "0"),
        factura: (this.$route.params.capturarTicket === 'Pay' ? this.$route.params.data.factura : "Si"),
        proveedor: (this.$route.params.capturarTicket === 'Pay' ? this.$route.params.data.proveedor : ""),        
        ciudad: (this.$route.params.capturarTicket === 'Pay' ? this.$route.params.data.proveedorr.ciudad : ""),        
        tiendasubiata: (this.$route.params.capturarTicket === 'Si' ? 'AAA' : this.$route.params.data.tiendasubiata),                
        concepto: (this.$route.params.capturarTicket === 'Pay' ? this.$route.params.data.concepto : ""),
        fecha: this.$moment().format("YYYY-MM-DD"), //this.$moment().format("YYYY-MM-DD"),
        tipo: (this.$route.params.capturarTicket === 'Pay' ? this.$route.params.data.tipo : "Total"),
        urgencia: (this.$route.params.capturarTicket === 'Pay' ? this.$route.params.data.urgencia : "3"),
        status: (this.$route.params.capturarTicket === 'Si' || this.$route.params.capturarTicket === 'Pay' ? "1 Recibido" : "1 Recibido") 
      },
      factura: [
        { value: "Si", text: "Si" },
        { value: "No", text: "No" }
      ],
      tipos: [
        { value: "Visita", text: "Visita" },
        { value: "Anticipo", text: "Anticipo" },
        { value: "Total", text: "Total" }
      ],
      urgencias: [        
        { value: "2", text: "Si" },
        { value: "3", text: "No" },        
      ],
      provSearched: "",
      initialDisplayProvs: "",
      codigo: "",
      nombre: "",
      ciudad: null,
      telefono: null,
      email: null,
      options: [],
      giros: [
        { value: "AAA", text: "NO DEFINIDO" },
        { value: "PTA", text: "PTA-Puertas de Aluminio" },
        { value: "ACC", text: "ACC-Aires Acondicionados" },
        { value: "PLO", text: "PLO-Plomeria" },
        { value: "COR", text: "COR-Cortinas de Acero" },
        { value: "LOS", text: "LOS-Losetas y Pizos" },
        { value: "ELE", text: "ELE-Electricista" },
        { value: "PIN", text: "PIN-Pintura" },
        { value: "ROC", text: "ROC-Tablaroca" },
        { value: "PLA", text: "PLA-Plagas y Fumigaciones" },
        { value: "HER", text: "HER-Herreria" },
        { value: "MAN", text: "MAN-Mantenimiento" },
        { value: "PUB", text: "PUB-Publicidad" },
        { value: "FER", text: "FER-Ferreterias" },
        { value: "CER", text: "CER-Cerrajeria" },
        { value: "CON", text: "CON-Construccion" },
        { value: "PAU", text: "PAU-Puertas Automaticas" },
        { value: "ALS", text: "ALS-Alarma y Seguridad" },
        { value: "PER", text: "PER-Persianas" },
        { value: "MEC", text: "MEC-Servicio Automotriz" },
        { value: "REN", text: "REN-Renta de Bodega u Oficinas" },
        { value: "MUL", text: "MUL-Multiservicios" },
        { value: "TIE", text: "TIE-Tiendas" },
        { value: "CAR", text: "CAR-Carpinteria" },
        { value: "TEC", text: "TEC-Servicio Tecnico" },
        { value: "PAQ", text: "PAQ-Paqueteria" },
        { value: "HOT", text: "HOT-Hotel" }
      ].sort(function(a, b) {
        var x = a.value.toLowerCase();
        var y = b.value.toLowerCase();
        if (x < y) {
          return -1;
        }
        if (x > y) {
          return 1;
        }
        return 0;
      })
    };
  },  
  computed: {    
  },
  methods: {
    async getUser(){
      axios.defaults.headers.common["Authorization"] = "Bearer " + this.$cookies.get("token");     
      let email64 = btoa( this.$store.getters.userEmail );
      let resp = await axios.get(process.env.VUE_APP_ROOT_API + `api/secure/usuarios/${email64}`);       
      let data = await resp.data;  
      //this.model.idusuario = data.id;
    },
    previewImage: function(event) {
      /*this.imageData = "data:image/jpeg;base64,";
      var input = event.target;
      this.imageExt = input.files[0].type.split("/").pop();
      if (this.imageExt === "pdf") {
        this.imageData = "data:application/pdf;base64,";
      }*/
      var input = event.target;
      for (let i = 0; i < input.files.length; i++) {
        var reader = new FileReader();
        reader.onload = e => {
          this.imageData = e.target.result;
          var ret = this.getXml();
          this.model.ticket = ret.ticket;         
          //this.model.fecha = ret.fecha;
          this.model.cantidad = ret.cantidad;
          this.query = ret.proveedor;
          this.nombre = ret.proveedor;
          this.model.concepto = ret.conceptos;
        };
        reader.readAsDataURL(input.files[i]);
      }
    },

    getXml(){

      let xml = atob(this.imageData.replace("data:text/xml;base64,", ""));

      let ret = {};
      parseString(xml, function (err, result) {
        let json = result;
        ret["ticket"] = json["cfdi:Comprobante"].$.Serie + "-" + json["cfdi:Comprobante"].$.Folio;
        ret["proveedor"] = json["cfdi:Comprobante"]["cfdi:Emisor"][0].$.Nombre;
        //ret["rfc"] = json["cfdi:Comprobante"]["cfdi:Emisor"][0].$.Rfc;
        ret["cantidad"] = json["cfdi:Comprobante"].$.Total;
        ret["fecha"] = dayjs(json["cfdi:Comprobante"].$.Fecha).format('YYYY-MM-DD');

        let conceptos = "";
        let n = json["cfdi:Comprobante"]["cfdi:Conceptos"][0]["cfdi:Concepto"].length;
        for (let i = 0; i < n; i++) {
          const concepto = json["cfdi:Comprobante"]["cfdi:Conceptos"][0]["cfdi:Concepto"][i];
          // conceptos += concepto.$.Cantidad + " X "

          // conceptos += concepto.$.ValorUnitario + " "

          conceptos += concepto.$.Descripcion
          if(i <= n-2){
            conceptos += "\n";
          }
        }

        ret["conceptos"] = conceptos; 


        



        /*ret["uuid"] = json["cfdi:Comprobante"]["cfdi:Complemento"][0]["tfd:TimbreFiscalDigital"][0].$.UUID;
        ret["total"] = json["cfdi:Comprobante"].$.Total;
        ret["fecha"] = json["cfdi:Comprobante"].$.Fecha;
        ret["formaPago"] = json["cfdi:Comprobante"].$.FormaPago;
        ret["tipocomp"] = json["cfdi:Comprobante"].$.TipoDeComprobante;            
        ret["rfcemi"] = json["cfdi:Comprobante"]["cfdi:Emisor"][0].$.Rfc;
        ret["rfcrec"] = json["cfdi:Comprobante"]["cfdi:Receptor"][0].$.Rfc;
        ret["nomrec"] = json["cfdi:Comprobante"]["cfdi:Receptor"][0].$.Nombre;            */
      });
      return ret;
    },


    async fetchProveedores(busqueda){    
      this.suggestions = [];
      this.provSelected = null;  
      if (busqueda === '' || busqueda === undefined || busqueda.length <= 3) {
          return;
      } 
      //console.log("busqueda: ",busqueda); 
      axios.defaults.headers.common["Authorization"] =
        "Bearer " + this.$cookies.get("token");     
      let resp = await axios.get(process.env.VUE_APP_ROOT_API + `api/secure/proveedores/${busqueda}`);       
      let data = await resp.data;      

      let task_names = await data.map((item) => {    
        if(item.ciudadd === null){
          return {"id": item.id, "nombrecomercial": item.nombrecomercial,"titular": item.titular, "idciudad": item.ciudad, "ciudad": "", "estado": "" }        
        }else{
          return {"id": item.id, "nombrecomercial": item.nombrecomercial,"titular": item.titular, "idciudad": item.ciudad, "ciudad": item.ciudadd.nombre, "estado": item.ciudadd.estado }        
        }
      });
      this.suggestions = [{data: task_names}];      
    },
    
    onInputChange(text){  
      //console.log(text)    
      if (text === '' || text === undefined) {
          return;
      }


    },
    selectHandler(item){

      if (item === undefined || item === null) {
        return
      }

      this.provSelected = item.item;
      this.localidad = item.item.ciudad + ", " + item.item.estado;
      this.query = item.item.nombrecomercial;      
      this.model.proveedor = item.item.id;
      this.model.ciudad = item.item.idciudad;
      this.suggestions = [];
      this.provSelected = null;  
      //this.model.ticket = item.item.id;
    },
    
    clickHandler(){      
    },

    abrirAltaRapidaProveedor(){
      axios.defaults.headers.common["Authorization"] =
        "Bearer " + this.$cookies.get("token");
      axios.get(process.env.VUE_APP_ROOT_API + "api/secure/ciudades").then(
        (response) => {
          var items = response.data;
          for (let item of items) {
            var ciudad = {};
            ciudad.value = item.id;
            ciudad.text = item.nombre;
            this.options.push(ciudad);
          }
          this.nombre = this.query.toLocaleUpperCase();
          this.$refs["altaRapidadProv"].show();
        },
        (error) => {
          //console.log("Error: " + JSON.stringify( error.data) );
        }
      );
      
    },

    altaRapidaProveedor(){
      axios.defaults.headers.common["Authorization"] = "Bearer " + this.$cookies.get("token");
      axios
        .post(process.env.VUE_APP_ROOT_API + "api/secure/proveedores", {
          codigo: this.codigo,  
          nombrecomercial: this.nombre,
          ciudad: this.ciudad,
          telefono: this.telefono,
          email: this.email,
        })
        .then(
          (response) => {
            

            this.mensajeAltaProv = "Se grabo correctamente...";
            this.dismissCountDown = this.dismissSecs;
            //this.$router.replace({ path: '/portal/proveedores' });
          },
          (error) => {
            //console.log("Error: " + JSON.stringify( error.data) );
          }
        );
    },

    agregarPago() {

      if(this.model.proveedor === ""){
        alert("No se ha asignado proveedor!");
      }else if(this.model.ticket === ""){
        alert("No se ha asignado una referencia!");
      }else{     
        this.model.ticket = 'ADMN-' + this.model.ticket;
        var initials = this.$store.getters.userName.replace(/[^a-zA-Z- ]/g, "").match(/\b\w/g);
        this.model.useremail = this.$store.getters.userEmail
        this.model.region = 'ADMN-' + initials.join('');
        this.model.ticket = this.model.ticket.replace(/\t/g, '');

        console.log("agregarPagoInt",  JSON.stringify(this.model));

        axios.defaults.headers.common["Authorization"] =
          "Bearer " + this.$cookies.get("token");
        axios
          .post(process.env.VUE_APP_ROOT_API + "api/secure/pagos", this.model)
          .then(
            response => {
              this.$router.push({ path: "/portal/pagos" });
            },
            error => {}
          );
      }
    },
    clear(){
            this.model.proveedor = null;
            this.provSearched = '';            
        },    
    regresar() {
      this.$router.push({ name: "Pagos" });
    },  
            
  }
};
</script>

<style>
/* textarea#conceptos{font-family: 'Courier New', monospace;} */

.xform-controlx{height: calc(2.25rem + 2px);}

.autosuggest__results {
  font-weight: 300;
  margin: 0;
  position: absolute;
  z-index: 10000001;
  width: 98%;
  border: 1px solid #e0e0e0;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  background: white;
  padding: 0px;
  max-height: 400px;
  overflow-y: scroll;
}

.autosuggest__results ul {
  list-style: none;
  padding-left: 5px;
  margin: 0;
}

.autosuggest__results .autosuggest__results-item:active,
.autosuggest__results .autosuggest__results-item:hover,
.autosuggest__results .autosuggest__results-item:focus,
.autosuggest__results .autosuggest__results-item--highlighted.autosuggest__results-item
{background: rgb(146, 226, 250);}
</style>