<template>
  <div id="pagosTemplate">
    <cat-generico
      title="PAGOS"
      awesome="false"
      :items="pagos"
      :fields="columnas"
      :totalRows="totalRows"
      :perPage="perPage"
      :desde="desde"
      :hasta="hasta"
      @onChange="onChange"
      @search="buscar"
      @programarPago="programarPago"
      @newPago="nuevoPago"
      @newRecord="checar"
      @editRecord="autorizar"
      @addPago="pagar"
      @deleteRecord="eliminar"
      @addImprimir="imprimir"
      @changeAvailablePage="cambiarPagina"
      @rowSelected="seleccionar"
      @datesForm="seleccionarFecha"
      @setFechaDesdeHasta="setFechaDesdeHasta"
    ></cat-generico>

    <b-modal
      ref="fechaDesdeHasta"
      title="Filtrar : "
      @ok="setFechaDesdeHasta(desde, hasta)"
    >
      <div class="form mt-4 ml-2">
        <b-form-group label-cols-sm="4" label-cols-lg="3" label="Desde : ">
          <input type="date" class="form-control w-60" v-model="desde" />
        </b-form-group>
      </div>
      <div class="form mt-4 ml-2">
        <b-form-group label-cols-sm="4" label-cols-lg="3" label="Hasta : ">
          <input type="date" class="form-control w-60" v-model="hasta" />
        </b-form-group>
      </div>
    </b-modal>

    <b-modal
      ref="modalProgramarPago"
      title="Programar Pago : "
      @ok="setFechaPago()"
    >
      <div class="form mt-4 ml-2">
        <b-form-group label-cols-sm="4" label-cols-lg="3" label="Fecha : ">
          <input type="date" class="form-control w-60" v-model="fechapago" />
        </b-form-group>
      </div>
    </b-modal>

    <b-modal
      ref="autVerEliminados"
      title="Auorizar ver eleminado : "
      @ok="verEliminados()"
    >
      <div class="form mt-4 ml-2">
        <b-form-group label-cols-sm="4" label-cols-lg="3" label="PIN : ">
          <input
            type="password"
            class="form-control w-30"
            v-model="autVerEliminados"
          />
        </b-form-group>
      </div>
    </b-modal>
  </div>
</template>

<script>
import axios from "axios";
import CatGenerico from "@/components/tools/CatPagos.vue";
import * as dayjs from "dayjs";
export default {
  components: {
    CatGenerico,
  },
  created() {},
  mounted() {
    //var numero = Math.round(parseFloat("117.88")  * 10) / 10;
    //console.log( numero.toFixed(2) );
    this.getTablas();
  },
  data() {
    return {
      rowId: undefined,
      pagos: [],
      columnas: [
        "id",
        "ticket",
        "cantidad",
        "factura",
        { key: "proveedorr", label: "Proveedor" },
        { key: "contacto", label: "Contacto" },
        { key: "tienda", label: "Sucursal" },
        //{ key: "tiendaciudad", label: "Localidad" },
        { key: "usuario", label: "Autorizó" },
        "region",
        "concepto",
        { key: "fecha", label: "Fecha" },
        { key: "fecharev", label: "FRev" },
        { key: "fechaaut", label: "FAut" },
        { key: "fechapago", label: "FPag" },
        "tipo",
        { key: "urgencia", label: "Urgente" },
        //{ key: "pago", label: "Pago" },
        { key: "status", label: "Estatus" },
      ],
      filtro: "todo",
      desde: dayjs().startOf("month").format("YYYY-MM-DD"),
      hasta: dayjs().format("YYYY-MM-DD"),
      fechapago: dayjs().endOf("month").format("YYYY-MM-DD"),
      autVerEliminados: "",
      totalRows: 450,
      currentPage: 1,
      perPage: 30,
      textoBuscar: "",
      filtroBuscar: "",
      selected: [],
    };
  },
  methods: {
    async getTablas() {
      var email = this.$store.getters.userEmail;
      var params =
        "?page=" +
        (this.currentPage - 1) +
        "&limit=" +
        this.perPage +
        "&searchtext=" +
        this.textoBuscar +
        "&filtro=" +
        this.filtroBuscar +
        "&email=" +
        email +
        "&desde=" +
        this.desde +
        "&hasta=" +
        this.hasta +
        "&pin=" +
        this.autVerEliminados;

      //this.filtroBuscar = '';

      //console.log(params);
      console.log(
        process.env.VUE_APP_ROOT_API + "api/secure/pagos/lista" + params
      );

      var resp = await axios.get(
        process.env.VUE_APP_ROOT_API + "api/secure/pagos/lista" + params
      );

      let items = resp.data.content;

      //console.log(JSON.stringify(resp.data.content));

      items.forEach(function (item) {
        if (item.status === "2 Revisado") {
          item["_rowVariant"] = "warning";
        }
        if (item.status === "3 Pagar") {
          item["_rowVariant"] = "success";
        }
      });

      this.pagos = resp.data.content;
      this.totalRows = resp.data.totalElements;
    },
    verEliminados() {
      this.filtroBuscar = "5 eliminados";
      this.getTablas();
    },
    seleccionarFecha() {
      //this.desde = "1962-11-13";//dayjs("").format("YYYY-MM-DD");
      this.$refs["fechaDesdeHasta"].show();
    },
    nuevoPago(items) {
      console.log(JSON.stringify(this.pagos));
      const params = { capturarTicket: "Si", id: "1", region: "ADMN" };
      this.$router.push({ name: "PagoInt", params: params });
      this.rowId = undefined;
    },
    checar(items) {
      if (items.length === 1 && items[0].status === "1 Recibido") {
        if (items[0].ticket.startsWith("ADMN-")) {
          this.$router.push({ name: "PagoCheckInt", params: items[0] });
        } else {
          this.$router.push({ name: "PagoCheck", params: items[0] });
        }
      } else {
        alert(
          "Debe seleccionar primero un pago que tenga estatus de '1 Recibido'."
        );
      }
    },
    autorizar(items) {
      if (items.length === 1 && items[0].status === "2 Revisado") {
        if (items[0].ticket.startsWith("ADMN-")) {
          this.$router.push({ name: "PagoCheckInt", params: items[0] });
        } else {
          this.$router.push({ name: "PagoCheck", params: items[0] });
        }
        //this.$router.push({ name: "PagoCheck", params: items[0] });
      } else {
        alert(
          "Debe seleccionar primero un pago que tenga estatus de '2 Revisado'."
        );
      }
    },

    pagar(items) {
      if (items.length === 1 && items[0].status === "3 Pagar") {
        if (this.$store.getters.userAdmin === "true") {
          if (items[0].ticket.startsWith("ADMN-")) {
            //console.log("model: ", JSON.stringify(items[0]));
            //items[0].fechapago = this.$moment().format("YYYY-MM-DD");
            const params = {
              capturarTicket: "Pay",
              id: "1",
              region: "ADMN",
              data: items[0],
            };
            this.$router.push({ name: "PagoPagarInt", params: params });
          } else {
            this.$router.push({ name: "PagoPagar", params: items[0] });
          }
        } else {
          alert("Solo Administradores pueden procesar pagos.");
        }
      } else if (items.length === 1 && items[0].status === "4 Finalizado") {
        if (items[0].ticket.startsWith("ADMN-")) {
          const params = {
            capturarTicket: "Pay",
            id: "1",
            region: "ADMN",
            data: items[0],
          };
          this.$router.push({ name: "PagoPagarInt", params: params });
        } else {
          this.$router.push({ name: "PagoPagar", params: items[0] });
        }
      } else {
        alert(
          "Debe seleccionar primero un pago que tenga estatus de '3 Pagar'."
        );
      }
    },
    buscar(texto, filtro) {
      this.textoBuscar = texto;
      this.filtroBuscar = "82342482348872347239";
      this.getTablas();
    },
    onChange(filtro) {
      if (filtro === "desde") {
        this.$refs["fechaDesde"].show();
      } else if (filtro === "hasta") {
        this.$refs["fechaHasta"].show();
      } else if (filtro === "eliminados") {
        this.$refs["autVerEliminados"].show();
      } else {
        if (filtro === "todo") {
          this.desde = "";
          this.hasta = this.$moment().format("YYYY-MM-DD");
          this.filtroBuscar = "";
          this.textoBuscar = "";
        } else {
          this.filtroBuscar = filtro;
          //this.textoBuscar = "";
        }
        this.getTablas();
      }
    },
    async setFechaDesdeHasta() {
      this.getTablas();
    },

    programarPago(item) {
      if (!(this.rowId === undefined)) {
        console.log(item.length);
        this.$refs["modalProgramarPago"].show();
      } else {
        alert("Debe seleccionar primero un pago.");
      }
    },

    setFechaPago() {
      if (!(this.rowId === undefined)) {
        alert(this.fechapago);
      } else {
        alert("Debe seleccionar primero un pago.");
      }
    },
    eliminar() {
      if (!(this.rowId === undefined)) {
        let eliminar = confirm("Desea eliminar el pago " + this.rowId + "?");
        if (eliminar) {
          axios.defaults.headers.common["Authorization"] =
            "Bearer " + this.$cookies.get("token");
          axios
            .delete(
              process.env.VUE_APP_ROOT_API + "api/secure/pagos/" + this.rowId
            )
            .then(
              (response) => {
                console.log(JSON.stringify(response.data));

                this.getTablas();
              },
              (error) => {}
            );

          alert("ok se elimino el pago");
        }
      } else {
        alert("Debe seleccionar primero un pago. ");
      }
    },
    imprimir(items) {
      let params = null;
      if (!(this.rowId === undefined)) {
        let item = items[0];
        params = {
          id: this.rowId,
          idproveedor: item.proveedorr.id,
          proveedor: item.proveedorr.nombrecomercial,
        };
      }
      this.$router.push({ name: "PagoImprimir", params: params });
    },
    cambiarPagina(numPage) {
      this.currentPage = numPage;
      this.getTablas();
    },
    seleccionar(row) {
      if (row.length > 0) {
        this.rowId = row[0].id;
      } else {
      }
    },
  },
};
</script>

<style>
</style>

