<template>
  <section class="dashboard-header">
    <div class="container-fluid">
      <div class="card-header">
        <h2>{{msg}} ticket: {{model.ticket}}</h2>
        <div></div>
      </div>

      <div id="pago" class="row bg-white has-shadow my-3 pb-3">
        <div class="col-md-10">
          <div class="form mt-4 ml-2">
            <b-form-group label-cols-sm="4" label-cols-lg="3" label="Factura ?: ">
              <b-form-input class="w-30" v-model="model.factura" disabled></b-form-input>
            </b-form-group>

            <b-form-group label-cols-sm="4" label-cols-lg="3" label="Proveedor: ">
              <b-form-input class="w-60" v-model="model.proveedorr.nombrecomercial" disabled></b-form-input>
            </b-form-group>

            <b-form-group label-cols-sm="4" label-cols-lg="3" label="Sucursal: ">
              <b-form-input class="w-40" v-model="tiendasubiata" disabled></b-form-input>
              <span class="ml-1">{{model.tienda.ciudadd.nombre}}, {{model.tienda.ciudadd.estado}}</span>
            </b-form-group>

            <b-form-group label-cols-sm="4" label-cols-lg="3" label="Concepto: ">
              <b-form-textarea rows="3" max-rows="10" v-model="model.concepto" disabled></b-form-textarea>
            </b-form-group>

            <b-form-group label-cols-sm="4" label-cols-lg="3" label="Fecha: ">
              <b-form-input type="date" class="w-50" v-model="model.fecha" disabled></b-form-input>
            </b-form-group>

            <b-form-group label-cols-sm="4" label-cols-lg="3" label="Cantidad: ">
              <b-form-input class="w-40" v-model="model.cantidad" disabled></b-form-input>
            </b-form-group>

            <b-form-group label-cols-sm="4" label-cols-lg="3" label="Tipo: ">
              <b-form-input class="w-30" v-model="model.tipo" disabled></b-form-input>
            </b-form-group>

            <b-form-group label-cols-sm="4" label-cols-lg="3" label="Urgencia: ">              
              <b-form-select :options="urgencias" class="w-30" v-model="model.urgencia"></b-form-select>
            </b-form-group>

            <b-form-group
              v-if="isProgramar"
              class="border-top pt-2"
              label-cols-sm="4"
              label-cols-lg="3"
              label="Fecha de Pago: "
            >
              <b-form-input type="date" class="w-50" v-model="model.fechapago"></b-form-input>
            </b-form-group>            

            <b-form inline class="mt-5">
              <b-btn class="float-right mr-5 ml-3" variant="secondary" @click="regresar">Regresar</b-btn>

              <b-form-checkbox
                v-if="estatus === '1 Recibido'"
                v-model="isProgramar"
                class="mb-2 ml-5"                
              >Programar el pago?</b-form-checkbox>

              <b-form-checkbox
                v-model="isValidar"
                class="mb-2 ml-5"
                v-if="estatus === '1 Recibido'"
              >Validar para su pago</b-form-checkbox>

              <b-form-checkbox
                v-model="isValidar"
                class="mb-2 ml-5"
                v-else-if="estatus === '2 Revisadoxxxx'"
              >Autorizar para su pago</b-form-checkbox>

              <div class="row" v-else-if="estatus === '2 Revisado'">
                <div class="col-3">
                  <span>Pin</span>
                </div>
                <div class="col-9">
                  <input type="password" class="form-control-sm w-100" v-model="model.pin">
                </div>

              </div>

              <b-btn
                class="float-right mr-3 ml-3"
                variant="primary"
                @click="validar"
                :disabled="!isValidar"
              >Validar</b-btn>
            </b-form>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      isValidar: false,
      isProgramar: false,
      msg: "Agregar solicitud de pago",
      turno: [
        { value: "mañana", text: "Por la mañana" },
        { value: "tarde", text: "Por la tarde" }
      ],
      urgencias: [        
        { value: "2", text: "Si" },
        { value: "3", text: "No" },        
      ],
      tiendasubiata: this.$route.params.tiendasubiata,
      model: this.$route.params,      
      //this.$route.params,
      estatus: this.$route.params.status
    };
  },
  mounted() {
    this.model.fechapago = null;//this.$moment().format("YYYY-MM-DD");
    this.model.turno = "mañana";    
    if (this.model.status === "2 Revisado") {
      this.isValidar = true;
    }
  },
  methods: {
    regresar() {
      this.$router.push({ name: "Pagos" });
      //this.$router.replace({ path: '/portal/pagos' });
    },
    validar() {
      if (this.model.status === "1 Recibido") {
        this.model.status = "2 Revisado";
      } else if (this.model.status === "2 Revisado") {
        this.model.useremail = this.$store.getters.userEmail;
        this.model.status = "3 Pagar";
      }
      axios.defaults.headers.common["Authorization"] =
        "Bearer " + this.$cookies.get("token");
      axios
        .post(process.env.VUE_APP_ROOT_API + "api/secure/pagos", this.model)
        .then(
          response => {
            if(response.data.id !== null){
              this.$router.push({ name: "Pagos" });
            }else{
              if (this.model.status === "3 Pagar") {
                alert("Falló la validación del PIN");
              }else{

              }
            }
            
          },
          error => {
            console.log("Error: " + JSON.stringify(error.data));
          }
        );
    }
  }
};
</script>

<style>
</style>