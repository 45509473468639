<template>
  <div>
    <tabla-generica 
    title = "Zonas"
    :rows = 'zonas' 
    :titles = 'titulos' 
    @newRecord = 'nuevo'
    @editRecord = 'editar'
    @rowSelect = 'seleccionar'
    ></tabla-generica>
    
    <!-- Modal Component -->
    <b-modal 
    id="modal1" 
    title="Tabla de Zonas"
    cancel-title = " No "
    ok-title=" Si "
    @ok="borrar">
      <p class="my-4">Desea eliminar la zona?</p>
    </b-modal>
  </div>
</template>

<script>
  import axios from 'axios';
  import TablaGenerica from '@/components/tools/TablaGenerica2.vue';

  export default {
    mounted: function() {
        this.getTablas()
      },
    components: {
      TablaGenerica
    },
    data() {
      return {
        rowId: undefined,
        usuarios: [],
        zonas: [],
        titulos: {
          id: {
            label: 'ID'            
          },
          nombre: {
            label: 'NOMBRE'
          }/*,
          'user.text': {
            label: 'USUARIO'
          }*/
        },      
        textoBuscar: 'sdfsdfsdfsdfsdfsdfsdfsd'
      }
    },
    methods: {

      getTablas() {        
        var token =  this.$cookies.get('token');
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
        if(this.zonas.length > 0){
          axios.all([            
            this.getZonas()
          ])
          .then(axios.spread((zonasResponse) => {
            this.zonas = zonasResponse;
          }))
        }else{
          axios.all([
            this.getUsuarios(),
            this.getZonas(),            
          ])
          .then(axios.spread((usuariosResponse, zonasResponse) => {
            this.usuarios = usuariosResponse.data;            
            this.zonas = zonasResponse.data;
          }))
        }
      },
      getZonas: function() {
        return axios.get(process.env.VUE_APP_ROOT_API + "api/secure/zonas");          
      },
      getUsuarios: function() {
        return axios.get(process.env.VUE_APP_ROOT_API + "api/secure/usuarios/list");          
      },      
      seleccionar(row){
        this.rowId = row.id;          
      },
      nuevo: function(){
        this.$router.push({ name: 'Zona', params: { id: 0, zona: {id: null, nombre: null}  }})            
      },
      editar: function(){
        if (!(this.rowId === undefined)){     
          this.$router.push({ name: 'Zona', params: { id: this.rowId, zona: this.zonas.find(row => row.id === this.rowId), usuarios: this.usuarios}})            
        }
      },        
      borrar: function() {
        if (!(this.rowId === undefined)){                 
          axios.defaults.headers.common['Authorization'] = 'Bearer ' + this.$cookies.get('token');
          axios.delete(process.env.VUE_APP_ROOT_API + "api/secure/zonas/" + this.rowId)
          .then((response) => {                      
            this.getZonas();
          }, (error) => {          
          })
        }
      },
    }
  }
</script>

<style>  
</style>