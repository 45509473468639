<template>
  <section class="dashboard-header">
    <div class="container-fluid">
      <div class="card-header">
        <h2>{{msg}}</h2>
        <div></div>
      </div>

      <div id="carpeta" class="bg-white has-shadow my-3 py-4 px-4">
        <b-card header="Lista de Pagos">
          <b-card-text>
            <b-row class>
              <b-col sm="6">
                <b-row class="my-2">
                  <b-col sm="5">
                    <label for="input-small" class="mt-1">Mes:</label>
                  </b-col>
                  <b-col sm="4">
                    <span class="mt-1">{{ fecha | moment("MMMM - YYYY") }}</span>
                  </b-col>
                  <b-col sm="3">
                    <b-button-group class="mx-1">
                      <b-button size="sm" variant="light" v-on:click="mesAnt">&laquo;</b-button>
                      <b-button size="sm" variant="light" v-on:click="mesSig">&raquo;</b-button>
                    </b-button-group>
                  </b-col>
                </b-row>

                <b-row class="my-2">
                  <b-col sm="5">
                    <label for="input-small">Periodo:</label>
                  </b-col>
                  <b-col sm="7">
                    <b-form-select v-model="periodo" :options="periodos" class></b-form-select>
                  </b-col>
                </b-row>

                <b-row class="my-2">
                  <b-col sm="5">
                    <label for="input-small">Tipo comprobante:</label>
                  </b-col>
                  <b-col sm="7">
                    <b-form-select v-model="tipocomp" :options="tipocomps" class></b-form-select>
                  </b-col>
                </b-row>

                <b-row class="my-2">
                  <b-col sm="5">
                    <label for="input-small">Ambito:</label>
                  </b-col>
                  <b-col sm="7">
                    <b-form-select v-model="ambito" :options="ambitos" class></b-form-select>
                  </b-col>
                </b-row>

                <b-row class="my-2">
                  <b-col sm="5">
                    <label for="input-small">Estatus pagos:</label>
                  </b-col>
                  <b-col sm="7">
                    <b-form-select v-model="estatus" :options="estatuss" class></b-form-select>
                  </b-col>
                </b-row>

                <b-row class="my-2">
                  <b-col sm="5">
                    <label for="input-small">Usuario:</label>
                  </b-col>
                  <b-col sm="7">
                    <b-form-select v-model="usuarios" :options="usuarioss" class></b-form-select>
                  </b-col>
                </b-row>

                <b-row class="my-2">
                  <b-col sm="5">
                    <label for="input-small"></label>
                  </b-col>
                  <b-col sm="7">
                    <b-form-group v-slot="{ ariaDescribedby }">
                    <b-form-radio-group
                      id="radio-group-1"
                      v-model="detalle"
                      :options="options"
                      :aria-describedby="ariaDescribedby"
                      name="radio-options"
                    ></b-form-radio-group>
                    </b-form-group>
                  </b-col>
                </b-row>
                
              </b-col>
              <b-col sm="6">
                <object
                  style="width: 97%; height: 25rem"
                  class="mx-3 my-3"
                  :data="pdf"
                  type="application/pdf"
                ></object>
              </b-col>
            </b-row>
          </b-card-text>
        </b-card>

        <b-row class="mt-3">
          <b-col lg="2" class="pb-2">
            <b-button size="sm" v-on:click="listaPagosImp('excel')">Descargar excel</b-button>
          </b-col>
          <b-col lg="2" class="pb-2">
            <b-button size="sm" v-on:click="listaPagosImp('pdf')">Imprimir</b-button>
          </b-col>
        </b-row>
      </div>
    </div>
  </section>
</template>

<script>
import axios from "axios";
import download from "downloadjs";

export default {
  data() {
    return {
      msg: "Imprimir",
      email: "",

      periodos: [        
        { value: "TODAY", text: "HOY" },        
        { value: "WEEK", text: "SEMANA" },        
        { value: "MONTH", text: "MES" },        
        { value: "YEAR", text: "AÑO" },        
        { value: "ALL", text: "TODO" },        
      ],
      periodo: "MONTH",

      ambito: "TODO",   
      ambitos: [        
        { value: "TODO", text: "TODOS LOS PAGOS" },        
      ],
      estatus: "0",
      estatuss: [
        { value: "0", text: "TODOS LOS ESTATUS"},
        { value: "1", text: "1 Recibido"},
        { value: "2", text: "2 Revisado"},
        { value: "3", text: "3 Pagar"},
        { value: "4", text: "4 Finalizado"}
      ],     
      tipocomp: "TODO",
      tipocomps: [
        { value: "TODO", text: "TODOS LOS COMPROBANTES"},
        { value: "No", text: "SIN FACTURA"},
        { value: "Si", text: "CON FACTURA"}        
      ], 
      detalle: 'd',
      options: [
          { text: 'DETALLE', value: 'd' },
          { text: 'AGRUPAR', value: 'a' }          
        ],
      usuarios: '0',
      usuarioss: [
          { text: 'TODOS', value: '0' }          
        ],
      params: this.$route.params,
      numCarpeta: this.$route.params.numCarpeta,
      fecha: this.$moment(this.$route.params.fechaCarpeta),
      pdf: "",
      pdfName: "REPORTE_INCIDENCIAS_TEMARQSA_"
    };
  },

  mounted() {

    if( JSON.stringify(this.params) !== "{}" ){
      this.ambitos.push( { value: this.params.idproveedor, text: this.params.proveedor} );   
      this.ambito = this.params.idproveedor;
    }


    if(this.$store.getters.userAdmin === 'true'){
      this.getUsuarios();
    }else{
      console.log('es admin');
    }
    


  },

  created() {    
  },
  methods: {

    async getUsuarios() {
      var params = "";
      var resp = await axios.get(
        process.env.VUE_APP_ROOT_API + "api/secure/pagos/usuarios" + params
      );            
      this.usuarioss = resp.data;      
    },

    mesAnt() {
      this.fecha = this.$moment(this.fecha).add(-1, "M");
    },
    mesSig() {
      this.fecha = this.$moment(this.fecha).add(1, "M");
    },
    listaPagosImp(tipo){
      this.pdf = "";
      var params = {       
        emailUsuario: (this.$store.getters.userAdmin? this.usuarios : this.$store.getters.userEmail),        
        fecha: this.fecha,
        tipocomp:  this.tipocomp,
        periodo: this.periodo,
        ambito: this.ambito,
        estatus: this.estatus,
        detalle: this.detalle,
        tipo: tipo
      }
      axios.defaults.headers.common["Authorization"] = "Bearer " + this.$cookies.get("token");
      axios.post(process.env.VUE_APP_ROOT_API + "api/secure/pagos/imprimir", params)
        .then(
          response => {
            if(tipo === 'pdf'){
              this.pdf = "data:application/pdf;base64," + response.data;
              //download("data:application/pdf;base64," + response.data, "oso.pdf","application/pdf");
            }else{              
              //var xls = "data:application/octet-stream;charset=utf-16le;base64," + response.data;            
              download("data:application/octet-stream;charset=utf-16le;base64," + response.data, 'extracto-pagos' + '.xlsx' , "application/octet-stream");            
            }                      
          },
          error => {}
        );
    },

    listaPagosImpxxx() {
      axios.defaults.headers.common["Authorization"] =
        "Bearer " + this.$cookies.get("token");
      var params =
        "?mes=" +
        this.fecha.format("MMMM-YYYY ").toUpperCase() +
        "&fecha=" +
        this.fecha.format("YYYY-MM-DD") +
        "&region=" +
        this.region +
        "&estatus=" +
        this.estatus;

      console.log(params);

      axios
        .get(
          process.env.VUE_APP_ROOT_API +
            "api/secure/pagos/imprimir" +
            params
        )
        .then(
          response => {
            this.pdf = "data:application/pdf;base64," + response.data;
          },
          error => {}
        );
    }
  }
};
</script>

<style>
</style>