<template>
    <nav v-bind:class="[$parent.isSidebarActive ? 'side-navbar' : 'side-navbar shrinked']">
          <!-- Sidebar Header-->
          <div class="sidebar-header d-flex align-items-center">
            <div class="avatar">
              <a v-on:click="perfil()">
                <avatar 
                  username="Jane Doe"
                  :src="imageData"                  
                ></avatar>
              </a>
              <!-- <img src="@/assets/img/avatar-1.jpg" alt="..." class="img-fluid rounded-circle"> -->              
            </div>
            <div class="title">
              <h1 class="h4">{{$store.getters.userName}} (Proyectos)</h1>
              <p>++ adm ++</p>              
            </div>
          </div>
          <!-- Sidebar Navidation Menus--><span class="heading">Inicio</span>
          <ul class="list-unstyled">
            <!-- <li :class="{'active': opt === 'ini'}" @click="opt = 'ini'"><a href="#/portal"> <i class="fa fa-home fa-lg"></i>Inicio </a></li>  -->           
          </ul>          

          <!-- Sidebar Navidation Menus--><span class="heading">Módulos</span>
          <ul class="list-unstyled">  
            <!-- <li :class="{'active': opt === 'incs'}" @click="opt = 'incs'"><a href="#/portal/incidencias"><i class="fa fa-address-card-o fa-lg"></i>Incidencias </a></li>             -->
                 <li :class="{'active': opt === 'pags'}" @click="opt = 'pags'"><a href="#/portal/pagos"><i class="fa fa-address-card-o fa-lg"></i>Pagos </a></li>            
          </ul>          

          <!-- Sidebar Navidation Menus--><span class="heading">Catálogos</span>
          <ul class="list-unstyled">                        
            <!-- <li :class="{'active': opt === 3}" @click="opt = 3"><a href="#/portal/proveedores"> <i class="fa fa-wrench fa-lg"></i>Proveedores </a></li>   -->                  
            
          </ul>

          <!-- Sidebar Navidation Menus--><span class="heading">Tablas</span>
      <!--     <ul class="list-unstyled">                        
            <li :class="{'active': opt === 'zon'}" @click="opt = 'zon'"><a href="#/portal/zonas"> <i class="fa fa-table fa-lg"></i>Zonas </a></li>
            <li :class="{'active': opt === 'rol'}" @click="opt = 'rol'"><a href="#/portal/roles"> <i class="fa fa-table fa-lg"></i>Roles </a></li>
            <li :class="{'active': opt === 'ciu'}" @click="opt = 'ciu'"><a href="#/portal/ciudades"> <i class="fa fa-table fa-lg"></i>Ciudades </a></li>
          </ul>   -->        

          <!-- Sidebar Navidation Menus--><!-- <span class="heading">Config.</span> -->
          
        </nav>

</template>

<script>
  import Vue from 'vue'
  import axios from 'axios';
  import Avatar from 'vue-avatar/src/Avatar'//'vue-avatar'
  export default {  
    mounted: function() {
        //this.previewImage();
        this.$root.$on('clickedSomething', () => {
          this.getPhoto();
        })

        this.getPhoto();
      },      
    data () {
      return {
        imageData: "",
        email: this.$store.getters.userEmail,
        isCollapsed: false,
        opt: 1        
      }
    },
    components: {
      Avatar
    },
    methods: {
      perfil(){
        this.$router.push({ name: 'Perfil', params: { email: this.email}}).catch(err => {
          // Ignore the vuex err regarding  navigating to the page they are already on.
          if (
            err.name !== 'NavigationDuplicated' &&
            !err.message.includes('Avoided redundant navigation to current location')
          ) {
            // But print any other errors to the console
            logError(err);
          }
        });           
      },
      getPhoto(){
        var emailUser = btoa( this.email ) + '.ava';
        var datos = {"nameFile":'', "emailUser": emailUser, "avatar": ''};
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + this.$cookies.get('token');
        axios.post(process.env.VUE_APP_ROOT_API + "api/secure/usuarios/avatar", datos)
        .then((response) => {                
            this.imageData = "data:image/jpeg;base64," + response.data.avatar;
            //this.$router.replace({ path: '/portal/' + this.tabla });                                                                 
        }, (error) => {
            console.log("Error: " + JSON.stringify( error.response.data.message) );
            })        
      },
      toggleClass: function(event) {
        if(this.isCollapsed){
          this.isCollapsed = false;
        }else{
          this.isCollapsed = true;
        }
      }
    }
  }
</script>

<style scoped>
ul.list-unstyled{
      padding: 7px 0;
  margin-bottom: 0.75rem
}
span.heading{
  font-weight: 500 !important;
  color: #67babd !important;
}
nav.side-navbar ul li a {
    padding: 5px 15px;    
    font-weight: 500;
    color: black
}

nav.side-navbar ul li li a {
    padding-left: 25px;    
}
nav.side-navbar.shrinked {
    min-width: 115px;
    max-width: 115px;
    text-align: center;
}
</style>